import './App.css';
import React, {Component, Container} from 'react';
import {Image,Navbar, Nav, NavItem, NavDropdown, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

import NavBarTop from "./component/iyao/navbar";
import Footer from "./component/iyao/footer";
import Apply from "./component/iyao/apply";
import About from "./component/iyao/about";
import Awards from './component/iyao/awards';
import Category from "./component/iyao/category";
import Terms from './component/iyao/terms';
import Jury from  './component/iyao/jury';
import Card from "./component/iyao/card";
import Contact from './component/iyao/contact';
import Winners from './component/iyao/winners';

import IYAOCNNavBarTop from './component/iyao-cn/navbar';
import IYAOCNFooter from './component/iyao-cn/footer';
import IYAOCNApply from './component/iyao-cn/apply';
import IYAOCNAbout from './component/iyao-cn/about';
import IYAOCNAwards from './component/iyao-cn/awards';
import IYAOCNCategory from './component/iyao-cn/category';
import IYAOCNTerms from './component/iyao-cn/terms';
import IYAOCNJury from './component/iyao-cn/jury';
import IYAOCNCard from './component/iyao-cn/card';
import IYAOCNContact from './component/iyao-cn/contact';
import IYAOCNWinners from './component/iyao-cn/winners';

import IYFAONavBarTop from "./component/iyfao/navbar";
import IYFAOFooter from "./component/iyfao/footer";
import IYFAOApply from "./component/iyfao/apply";
import IYFAOAbout from "./component/iyfao/about";
import IYFAOAwards from './component/iyfao/awards';
import IYFAOCategory from "./component/iyfao/category";
import IYFAOTerms from './component/iyfao/terms';
import IYFAOJury from  './component/iyfao/jury';
import IYFAOCard from "./component/iyfao/card";
import IYFAOContact from './component/iyfao/contact';
import IYFAOWinners from './component/iyfao/winners';

import IESADNavBarTop from "./component/iesad/navbar";
import IESADFooter from "./component/iesad/footer";
import IESADApply from "./component/iesad/apply";
import IESADAbout from "./component/iesad/about";
import IESADAwards from './component/iesad/awards';
import IESADCategory from "./component/iesad/category";
import IESADTerms from './component/iesad/terms';
import IESADJury from  './component/iesad/jury';
import IESADCard from "./component/iesad/card";
import IESADContact from './component/iesad/contact';
import IESADWinners from './component/iesad/winners';

import Home from "./component/iyao/home";
import IYFAOHome from "./component/iyfao/home";
import IYAOCNHome from './component/iyao-cn/home';
import IESADHome from './component/iesad/home';

import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import IndxePage from './component/indexpage';
import IYAOMagazine from './component/iyao/magazine';

const applyurl = "https://docs.google.com/forms/d/1JQvox_w4JTU0rvRcnRxPnfGxWmQBuq5V0LoMmVBY9Gs/"



const content = {
  "en":{
    "home":"Home","market":"Market", "others":"Others", "companyname": "International Young Artist Open Competition", "companytagline":"Hong Kong", "extratagline": "Music Category", "companyintro": "ONLINE COMPETITION", "introcontent":" Open to Piano , Strings , Brass , Woodwind , Voice\nDeadline : 15th November 2022",
    },
  "zh":{
    "home":"主頁","market":"市場動態", "others":"其他",  "companyname": "海克斯國際有限公司", "companytagline":"您的財務合作夥伴", "extratagline": "---全新NFT即將發售---", "companyintro": "公司簡介", "introcontent":"海克斯國際有限公司是一家領先的數字化金融科技公司，專注於為用戶提供覆蓋多個市場的全數字化在線券商服務，繼而變革投資體驗。海克斯通過自主研發的一站式數字化金融服務平台，提供面向C端的市場數據、資訊、社交等服務，海克斯提供數字貨幣、現貨黃金、外匯交易、財富管理等服務，並以用户為中心構建完善的金融科技生態系統，連接用戶、投資者、企業、分析師、媒體和意見領袖。同時，從而實現企業業務與零售經紀業務的高度互補。"+"\n\r"+"槓桿式外匯交易、就數字貨幣提供意見、就現貨黃金提供意見、提供自動化交易服務、提供資產管理的資質。"+"\n\r"+"未來，海克斯將繼續秉承體驗至上，用戶第一的理念，不斷推動產品疊代升級，推出更多普惠投資者、企業和行業的產品及服務，成為有顯著影響力的全球金融服務企業。",
  },
  "cn":{
    "home":"主页","market":"市场动态", "others":"其他", "companyname": "海克斯国际有限公司", "companytagline":"您的财务合作伙伴.", "extratagline": "---全新NFT即将发售---", "companyintro": "公司简介", "introcontent":"海克斯国际有限公司是一家领先的数字化金融科技公司，专注于为用户提供覆盖多个市场的全数字化在线券商服务，继而变革投资体验。海克斯通过自主研发的一站式数字化金融服务平台，提供面向C端的市场数据、资讯、社交等服务，海克斯提供数字货币、现货黄金、外汇交易、财富管理等服务，并以用户为中心构建完善的金融科技生态系统，连接用户、投资者、企业、分析师、媒体和意见领袖。同时，从而实现企业业务与零售经纪业务的高度互补。 "+"\n\r"+"杠杆式外汇交易、就数字货币提供意见、就现货黄金提供意见、提供自动化交易服务、提供资产管理的资质。 "+"\n\r"+"未来，海克斯将继续秉承体验至上，用户第一的理念，不断推动产品叠代升级，推出更多普惠投资者、企业和行业的产品及服务，成为有显著影响力的全球金融服务企业。",
  },
}
class App extends Component {
  constructor(props){
    super(props);
    this.state = {  
        lang:"en",
        language:{"en":"ENG", "zh":"繁","cn":"简"},
        companyname:{
          "en":"Hicus",
          "zh":"海克斯",
          "cn":"海克斯"
        },
        pageSelected:"home",
        chosencurrency:"USD",
        pagepos:"",
        cardcontent: {
          "en": { "title1":"About the Competition",
                  "tagline1":"Our Vision",
                  "content1":"The International Young Artist Open Competition serves as one of the leading platforms for young artists to realize their endeavours, and to learn and communicate with talented artists around the region and the world.\nThe competition is divided into three categories - Music, Art and Speech that provides a comfortable environment and experience for participants across the region.\nThe competition's professional jury from Europe and US offer advice for participants who would otherwise need to travel abroad. Participants in Asia with technical restrictions can benefit from expert comments and artistic guidance from our jury. It is a competition for young artists of all levels of playing to enjoy, and a great opportunity for future artists to gain much formal experience, technique and style assessment, and the joy of performing. \n The competition is organised by the Yréte Ténue International Culture Fellowship.",
                  "title2":"Industry",
                  "tagline2":"Redefining Finance",
                  "content2":"The mission of Hicus International is to enhance the confidence of investment clients in Hicus International and the industry, and at the same time hope that it can become a good example for the industry and contribute to the financial industry in Hong Kong and overseas!",
                  "title3":"Society",
                  "tagline3":"Mission to Society",
                  "content3":"As a part of society, we have a responsibility based on the principle of reciprocating the society. We thus support the charitable work of Hong Kong NGOs and give back to the community."
                },
          "zh": { "title1":"願景",
                      "tagline1":"海克斯的願景",
                      "content1":"海克斯國際作為香港金融界的一份子，我們秉承「誠信、穩健、創新」的服務宗旨，當中「誠信」是我們最重視的。",
                      "title2":"業界",
                      "tagline2":"對金融業界的使命",
                      "content2":"海克斯國際的使命就是增強投資客戶對海克斯國際及業界的信心，同時希望自己能成為同業的好榜樣，為香港以及海外的金融業出一分力！",
                      "title3":"社會",
                      "tagline3":"對社會的使命",
                      "content3":"作為社會的一份子，本着「取諸社會，用諸社會」的原則，我們有責任。支援香港非政府組織的慈善工作，回饋社會。"
                    },
          "cn": { "title1":"比赛简介",
                  "tagline1":"我们的愿景",
                  "content1":"IYAO国际青年艺术家公开赛是年轻艺术家实现自己的努力、与地区和世界各地有才华的艺术家学习和交流的主要平台之一。\n比赛分为三个类别 - 音乐、艺术和演讲为整个地区的参赛者提供舒适的环境和体验。\n比赛由来自欧洲和美国的专业评审团为需要出国旅行的参赛者提供建议。受到現場性限制的参赛者可以从专家评论中受益於评委会的指导。这是一场适合各个演奏水平的年轻艺术家享受的比赛，也是未来艺术家获得大量正式经验、技术和风格评估以及表演乐趣的绝佳机会。 \n",
                  "title2":"行业",
                  "tagline2":"重新定义金融",
                  "content2":"Hicus国际的使命是增强投资客户对Hicus国际及行业的信心，同时希望能够成为行业的良好榜样，为香港金融业做出贡献 还有海外！",
                  "title3":"社会",
                  "tagline3":"社会使命",
                  "content3":"作为社会的一份子，我们有责任本着回馈社会的原则，支持香港非政府组织的慈善工作，回馈社会。"
         },
        },
        iyfaocardcontent: {
          "en": { "title1":"About the Competition",
                  "tagline1":"Our Vision",
                  "content1":"The International Young Fine Art Artist Open Competition serves as one of the leading platforms for young artists to realize their endeavours, and to learn and communicate with talented artists around the region and the world.\nThe competition is part of the competition series covering Music, Art and Speech that provides a comfortable environment and experience for participants across the globe.\nThe competition's professional jury from Europe and US offer advice for participants who would otherwise difficult to reach out to. Participants in Asia with technical restrictions can benefit from expert comments and artistic guidance from our jury. It is a competition for young artists of all levels of proficiency to enjoy, and a great opportunity for future artists to gain much formal experience, techniques and style assessment, and of course, the joy of performing. \n The competition is organised by the Yréte Ténue International Culture Fellowship.",
                  "title2":"Industry",
                  "tagline2":"Redefining Finance",
                  "content2":"The mission of Hicus International is to enhance the confidence of investment clients in Hicus International and the industry, and at the same time hope that it can become a good example for the industry and contribute to the financial industry in Hong Kong and overseas!",
                  "title3":"Society",
                  "tagline3":"Mission to Society",
                  "content3":"As a part of society, we have a responsibility based on the principle of reciprocating the society. We thus support the charitable work of Hong Kong NGOs and give back to the community."
                },
          "zh": { "title1":"願景",
                      "tagline1":"海克斯的願景",
                      "content1":"海克斯國際作為香港金融界的一份子，我們秉承「誠信、穩健、創新」的服務宗旨，當中「誠信」是我們最重視的。",
                      "title2":"業界",
                      "tagline2":"對金融業界的使命",
                      "content2":"海克斯國際的使命就是增強投資客戶對海克斯國際及業界的信心，同時希望自己能成為同業的好榜樣，為香港以及海外的金融業出一分力！",
                      "title3":"社會",
                      "tagline3":"對社會的使命",
                      "content3":"作為社會的一份子，本着「取諸社會，用諸社會」的原則，我們有責任。支援香港非政府組織的慈善工作，回饋社會。"
                    },
          "cn": { "title1":"愿景",
                      "tagline1":"海克斯的愿景",
                      "content1":"海克斯国际作为香港金融界的一份子，我们秉承「诚信、稳健、创新」的服务宗旨，当中「诚信」是我们最重视的。",
                      "title2":"业界",
                      "tagline2":"对金融业界的使命",
                      "content2":"海克斯国际的使命就是增强投资客户对海克斯国际及业界的信心，同时希望自己能成为同业的好榜样，为香港以及海外的金融业出一分力！",
                      "title3":"社会",
                      "tagline3":"对社会的使命",
                      "content3":"作为社会的一份子，本着「取诸社会，用诸社会」的原则，我们有责任。支援香港非政府组织的慈善工作，回馈社会。"
                    },
        },
        iesadcardcontent: {
          "en": { "title1":"About the Competition",
                  "tagline1":"Our Vision",
                  "content1":"The International English Speech Art And Drama Competition aims at unleashing young speakers' potential and to realize their endeavours through expressing and competing with talented artists around their region and the globe.\nThe competition is part of the competition series covering Music, Art and Speech that provide a comfortable environment and experience for participants across the world.\nOur adjudicators consist of veteran educators providing online assessments that would otherwise be difficult to reach. Participants in with technical restrictions can benefit from expert comments and linguistic guidance from our jury. It is a competition for young speakers of all levels of proficiency to enjoy, and a great opportunity for future speakers to gain much formal experience, technique, style assessment, and of course, the joy of performing.\n The competition is organised by the Yréte Ténue International Culture Fellowship.",
                  "title2":"Industry",
                  "tagline2":"Redefining Finance",
                  "content2":"The mission of Hicus International is to enhance the confidence of investment clients in Hicus International and the industry, and at the same time hope that it can become a good example for the industry and contribute to the financial industry in Hong Kong and overseas!",
                  "title3":"Society",
                  "tagline3":"Mission to Society",
                  "content3":"As a part of society, we have a responsibility based on the principle of reciprocating the society. We thus support the charitable work of Hong Kong NGOs and give back to the community."
                },
          "zh": { "title1":"願景",
                      "tagline1":"海克斯的願景",
                      "content1":"海克斯國際作為香港金融界的一份子，我們秉承「誠信、穩健、創新」的服務宗旨，當中「誠信」是我們最重視的。",
                      "title2":"業界",
                      "tagline2":"對金融業界的使命",
                      "content2":"海克斯國際的使命就是增強投資客戶對海克斯國際及業界的信心，同時希望自己能成為同業的好榜樣，為香港以及海外的金融業出一分力！",
                      "title3":"社會",
                      "tagline3":"對社會的使命",
                      "content3":"作為社會的一份子，本着「取諸社會，用諸社會」的原則，我們有責任。支援香港非政府組織的慈善工作，回饋社會。"
                    },
          "cn": { "title1":"愿景",
                      "tagline1":"海克斯的愿景",
                      "content1":"海克斯国际作为香港金融界的一份子，我们秉承「诚信、稳健、创新」的服务宗旨，当中「诚信」是我们最重视的。",
                      "title2":"业界",
                      "tagline2":"对金融业界的使命",
                      "content2":"海克斯国际的使命就是增强投资客户对海克斯国际及业界的信心，同时希望自己能成为同业的好榜样，为香港以及海外的金融业出一分力！",
                      "title3":"社会",
                      "tagline3":"对社会的使命",
                      "content3":"作为社会的一份子，本着「取诸社会，用诸社会」的原则，我们有责任。支援香港非政府组织的慈善工作，回馈社会。"
                    },
        },
        comp:null,
    };

    this.setval = this.setval.bind(this)
  }
  componentDidMount(){
    // fetch("https://dataapi.2rich.net/QueryDataHistory/QueryDataJsonByCountJson.aspx?userCode=FX168&bCode=FEAGUS&succ_callback=jQuery112304707026314812346_1651155474723&_=1651155474738")
    // .then((response)=>{console.log(response.json())})
  }

  setval(obj){
    this.setState(obj)
  }

  render(){
    return (
    //  { <PayPalScriptProvider options={{'client-id':"AYjS8JJEeQjpxiAmTyDqOJANSOJgxgmjuf4IPFRIqmqsu0oZlrGZ-vz0NB5p86pfNcf55-zEWpznAy4O",
    //                                    //dcurrency:this.state.chosencurrency,
    //                                    }}>}
      <div className="App">
        
          
        <div className="nav-container">
            {
              (this.state.comp==="iyao") && <NavBarTop {...this.state} setval={this.setval} />
            }
             {
              (this.state.comp==="iyao-cn") && <IYAOCNNavBarTop {...this.state} setval={this.setval} />
            }
             {
              (this.state.comp==="iyfao") && <IYFAONavBarTop {...this.state} setval={this.setval} />
            }
             {
              (this.state.comp==="iesad") && <IESADNavBarTop {...this.state} setval={this.setval} />
            }
        </div>

          <BrowserRouter>
            <Routes>

                <Route index element={<IndxePage {...this.state} setval={this.setval}/>} />
                {/* <Route path="iyao" element={<Home {...this.state} setval={this.setval}/>}> */}
                  <Route path="/iyao" element={<Home {...this.state} setval={this.setval}/>} />
                  <Route path="/iyao/about" element={<About {...this.state} setval={this.setval}/>} />
                  <Route path="/iyao/apply" element={<Apply {...this.state} setval={this.setval} />} />
                  <Route path="/iyao/category" element={<Category {...this.state} setval={this.setval}/>} />
                  <Route path="/iyao/awards" element={<Awards {...this.state} setval={this.setval} />} />
                  <Route path="/iyao/jury" element={<Jury {...this.state} setval={this.setval}/> } />
                  <Route path="/iyao/terms" element={<Terms {...this.state} setval={this.setval} />}  />
                  <Route path="/iyao/contact" element={<Contact {...this.state} setval={this.setval}/>}  />
                  <Route path="/iyao/winners" element={<Winners {...this.state} setval={this.setval}/>}  />
                  <Route path="/iyao/magazine" element={<IYAOMagazine {...this.state} setval={this.setval}/>}  />
                {/* </Route> */}

                  {/* <Route path="iyao" element={<Home {...this.state} setval={this.setval}/>}> */}
                  <Route path="/iyao-cn" element={<IYAOCNHome {...this.state} setval={this.setval}/>} />
                  <Route path="/iyao-cn/about" element={<IYAOCNAbout {...this.state} setval={this.setval}/>} />
                  <Route path="/iyao-cn/apply" element={<IYAOCNApply {...this.state} setval={this.setval} />} />
                  <Route path="/iyao-cn/category" element={<IYAOCNCategory {...this.state} setval={this.setval}/>} />
                  <Route path="/iyao-cn/awards" element={<IYAOCNAwards {...this.state} setval={this.setval} />} />
                  <Route path="/iyao-cn/jury" element={<IYAOCNJury {...this.state} setval={this.setval}/> } />
                  <Route path="/iyao-cn/terms" element={<IYAOCNTerms {...this.state} setval={this.setval} />}  />
                  <Route path="/iyao-cn/contact" element={<IYAOCNContact {...this.state} setval={this.setval}/>}  />
                  <Route path="/iyao-cn/winners" element={<IYAOCNWinners {...this.state} setval={this.setval}/>}  />
                {/* </Route> */}


                {/* <Route path="iyfao" element={<IYFAOHome {...this.state} setval={this.setval}/>}> */}
                  <Route path="/iyfao" element={<IYFAOHome {...this.state} setval={this.setval}/>} />
                  <Route path="/iyfao/about" element={<IYFAOAbout {...this.state} setval={this.setval}/>} />
                  <Route path="/iyfao/apply" element={<IYFAOApply {...this.state} setval={this.setval} />} />
                  <Route path="/iyfao/category" element={<IYFAOCategory {...this.state} setval={this.setval}/>} />
                  <Route path="/iyfao/awards" element={<IYFAOAwards {...this.state} setval={this.setval} />} />
                  <Route path="/iyfao/jury" element={<IYFAOJury {...this.state} setval={this.setval}/> } />
                  <Route path="/iyfao/terms" element={<IYFAOTerms {...this.state} setval={this.setval}/>}  />
                  <Route path="/iyfao/contact" element={<IYFAOContact {...this.state} setval={this.setval}/>}  />
                  <Route path="/iyfao/winners" element={<IYFAOWinners {...this.state} setval={this.setval}/>}  />
                {/* </Route> */}


                  {/* <Route path="iyfao" element={<IYFAOHome {...this.state} setval={this.setval}/>}> */}
                  <Route path="/iesad" element={<IESADHome {...this.state} setval={this.setval}/>} />
                  <Route path="/iesad/about" element={<IESADAbout {...this.state} setval={this.setval}/>} />
                  <Route path="/iesad/apply" element={<IESADApply {...this.state} setval={this.setval} />} />
                  <Route path="/iesad/category" element={<IESADCategory {...this.state} setval={this.setval}/>} />
                  <Route path="/iesad/awards" element={<IESADAwards {...this.state} setval={this.setval} />} />
                  <Route path="/iesad/jury" element={<IESADJury {...this.state} setval={this.setval}/> } />
                  <Route path="/iesad/terms" element={<IESADTerms {...this.state} setval={this.setval}/>}  />
                  <Route path="/iesad/contact" element={<IESADContact {...this.state} setval={this.setval}/>}  />
                  {/* <Route path="/iesad/winners" element={<IYFAOWinners {...this.state} setval={this.setval}/>}  /> */}
                {/* </Route> */}

                
            </Routes>
          </BrowserRouter>
      
      {
        (this.state.comp==="iyao") && <Footer {...this.state} setval={this.setval}/>
      }

{
        (this.state.comp==="iyao-cn") && <IYAOCNFooter {...this.state} setval={this.setval}/>
      }
      
      {
        (this.state.comp==="iyfao") && <IYFAOFooter {...this.state} setval={this.setval}/>
      }

{
        (this.state.comp==="iesad") && <IESADFooter {...this.state} setval={this.setval}/>
      }
        

      </div>
      // {</PayPalScriptProvider>}
    );
  }
  }
  

export default App;
