import iesadPrice from "./pricelist"; 

const iesadGroupCat = {
    "Poetry":{
        1:{
            "id":1,"number":"PT1","name":"Debut","des":"Age 7 or below, any type of poetry under 3 minutes", "price":iesadPrice["PT1"]
        },
        2:{
            "id":2,"number":"PT2","name":"Junior","des":"Age 8-10 , any type of poetry under 4 minutes", "price":iesadPrice["PT2"]
        },
        3:{
            "id":3,"number":"PT3","name":"Intermediate","des":"Age 11-14, any type of poetry under 5 minutes", "price":iesadPrice["PT3"]
        },
        4:{
            "id":4,"number":"PT4","name":"Youth","des":"Age 15-18, any type of poetry under 6 minutes", "price":iesadPrice["PT4"]
        },
        5:{
            "id":5,"number":"PT5","name":"Adult","des":"Age 18 or above, any type of poetry under 6 minutes", "price":iesadPrice["PT5"]
        },
        // 6:{
        //     "id":6,"number":"PT6","name":"Open","des":"Age 18 or above, any type of poetry under 6 minutes", "price":iesadPrice["PT6"]
        // },
    },
    "Prose":{
        1:{
            "id":1,"number":"PR1","name":"Debut","des":"Age 7 or below, any prose, non-dramatically spoken under 3 minutes", "price":iesadPrice["PR1"]
        },
        2:{
            "id":2,"number":"PR2","name":"Junior","des":"Age 8-10 , any prose, non-dramatically spoken under 4 minutes", "price":iesadPrice["PR2"]
        },
        3:{
            "id":3,"number":"PR3","name":"Intermediate","des":"Age 11-14, any prose, non-dramatically spoken under 5 minutes", "price":iesadPrice["PR3"]
        },
        4:{
            "id":4,"number":"PR4","name":"Youth","des":"Age 15-18, any prose, non-dramatically spoken under 6 minutes", "price":iesadPrice["PR4"]
        },
        5:{
            "id":5,"number":"PR5","name":"Adult","des":"Age 18 or above, any prose, non-dramatically spoken under 6 minutes", "price":iesadPrice["PR5"]
        },
        // 6:{
        //     "id":6,"number":"PR6","name":"Open","des":"Age 18 or above, any prose, non-dramatically spoken under 6 minutes", "price":iesadPrice["PR6"]
        // },
    },
    "Dramatic Performance":{
        "Solo":{
            1:{
                "id":1,"number":"DS1","name":"Primary","des":"Age 7 or below, any theatre scene, under 3 minutes", "price":iesadPrice["DS1"]
            },
            2:{
                "id":2,"number":"DS2","name":"Junior","des":"Age 8-10 , any theatre scene, under 4 minutes", "price":iesadPrice["DS2"]
            },
            3:{
                "id":3,"number":"DS3","name":"Intermediate","des":"Age 11-14, any theatre scene, under 5 minutes", "price":iesadPrice["DS3"]
            },
            4:{
                "id":4,"number":"DS4","name":"Youth","des":"Age 15-18, any theatre scene, under 6 minutes", "price":iesadPrice["DS4"]
            },
            5:{
                "id":5,"number":"DS5","name":"Adult","des":"Age 18 or above, any theatre scene, under 6 minutes", "price":iesadPrice["DS5"]
            },
            // 6:{
            //     "id":6,"number":"DS6","name":"Adults","des":"Age 18 or above, any theatre scene, under 6 minutes", "price":iesadPrice["DS6"]
            // },
        },
        "Duologue":{
            1:{
                "id":1,"number":"DD1","name":"Primary","des":"Age 7 or below, any theatre scene, under 3 minutes", "price":iesadPrice["DD1"]
            },
            2:{
                "id":2,"number":"DD2","name":"Junior","des":"Age 8-10 , any theatre scene, under 4 minutes", "price":iesadPrice["DD2"]
            },
            3:{
                "id":3,"number":"DD3","name":"Intermediate","des":"Age 11-14, any theatre scene, under 5 minutes", "price":iesadPrice["DD3"]
            },
            4:{
                "id":4,"number":"DD4","name":"Youth","des":"Age 15-18, any theatre scene, under 6 minutes", "price":iesadPrice["DD4"]
            },
            5:{
                "id":5,"number":"DD5","name":"Adult","des":"Age 18 or above, any theatre scene, under 6 minutes", "price":iesadPrice["DD5"]
            },
            // 6:{
            //     "id":6,"number":"DD6","name":"Adults","des":"Age 18 or above, any theatre scene, under 6 minutes", "price":iesadPrice["DD6"]
            // },
        },
        "Group":{
            1:{
                "id":1,"number":"DG1","name":"Primary","des":"Age 7 or below, any theatre scene, under 3 minutes", "price":iesadPrice["DG1"]
            },
            2:{
                "id":2,"number":"DG2","name":"Junior","des":"Age 8-10 , any theatre scene, under 4 minutes", "price":iesadPrice["DG2"]
            },
            3:{
                "id":3,"number":"DG3","name":"Intermediate","des":"Age 11-14, any theatre scene, under 5 minutes", "price":iesadPrice["DG3"]
            },
            4:{
                "id":4,"number":"DG4","name":"Youth","des":"Age 15-18, any theatre scene, under 6 minutes", "price":iesadPrice["DG4"]
            },
            5:{
                "id":5,"number":"DG5","name":"Adult","des":"Age 18 or above, any theatre scene, under 6 minutes", "price":iesadPrice["DG5"]
            },
            // 6:{
            //     "id":6,"number":"DG6","name":"Adults","des":"Age 18 or above, any theatre scene, under 6 minutes", "price":iesadPrice["DG6"]
            // },
        },
      
    },
    "Storytelling":{
        1:{
            "id":1,"number":"ST1","name":"Debut","des":"Age 7 or below, original or non-original story of any type under 3 minutes", "price":iesadPrice["ST1"]
        },
        2:{
            "id":2,"number":"ST2","name":"Junior","des":"Age 8-10 , original or non-original story of any type under 4 minutes", "price":iesadPrice["ST2"]
        },
        3:{
            "id":3,"number":"ST3","name":"Intermediate","des":"Age 11-14, original or non-original story of any type under 5 minutes", "price":iesadPrice["ST3"]
        },
        4:{
            "id":4,"number":"ST4","name":"Youth","des":"Age 15-18, original or non-original story of any type under 6 minutes", "price":iesadPrice["ST4"]
        },
        5:{
            "id":5,"number":"ST5","name":"Adult","des":"Age 18 or above, original or non-original story of any type under 6 minutes", "price":iesadPrice["ST5"]
        },
        // 6:{
        //     "id":6,"number":"ST6","name":"Open","des":"Age 18 or above, original or non-original story of any type under 6 minutes", "price":iesadPrice["ST6"]
        // },
    },
    "Public Speaking":{
        1:{
            "id":1,"number":"PS1","name":"Debut","des":"Age 7 or below, persuasive in nature, free topic, originally written by speaker under 3 minutes", "price":iesadPrice["PS1"]
        },
        2:{
            "id":2,"number":"PS2","name":"Junior","des":"Age 8-10 , persuasive in nature, free topic, originally written by speaker under 4 minutes", "price":iesadPrice["PS2"]
        },
        3:{
            "id":3,"number":"PS3","name":"Intermediate","des":"Age 11-14, persuasive in nature, free topic, originally written by speaker under 5 minutes", "price":iesadPrice["PS3"]
        },
        4:{
            "id":4,"number":"PS4","name":"Youth","des":"Age 15-18, persuasive in nature, free topic, originally written by speaker under 6 minutes", "price":iesadPrice["PS4"]
        },
        5:{
            "id":5,"number":"PS5","name":"Adult","des":"Age 18 or above, persuasive in nature, free topic, originally written by speaker under 6 minutes", "price":iesadPrice["PS5"]
        },
        // 6:{
        //     "id":6,"number":"PS6","name":"Open","des":"Age 18 or above, persuasive in nature, free topic, originally written by speaker under 6 minutes", "price":iesadPrice["PS6"]
        // },
    },
   

}

export default iesadGroupCat;