const groupCat = {
    "piano" :{
        0 : {
            1:{
            "id":1,"number":"P01", "name":"John Thompson Easiest Piano Course Part One", "des":"Any 1 piece from the book", "price":50,"name-cn":"约翰·汤普森最简单的钢琴课程第一冊","des-cn":"书本中的任何一首曲目"
            },
            2:{
            "id":2,"number":"P02", "name":"John Thompson Easiest Piano Course Part Two", "des":"Any 1 piece from the book", "price":50,"name-cn":"约翰·汤普森最简单的钢琴课程第二冊","des-cn":"书本中的任何一首曲目"
            },
            3:{
            "id":3,"number":"P03", "name":"Piano Lesson Made Easy 1", "des":"Any 1 piece from the book", "price":50,"name-cn":"Piano Lesson Made Easy第一冊","des-cn":"书本中的任何一首曲目"
            },
            4:{
            "id":4,"number":"P04", "name":"Piano Lesson Made Easy 2", "des":"Any 1 piece from the book", "price":50,"name-cn":"Piano Lesson Made Easy第二冊","des-cn":"书本中的任何一首曲目"
            },
            5:{
            "id":5,"number":"P05", "name":"Piano Lesson Made Easy 3", "des":"Any 1 piece from the book", "price":50,"name-cn":"Piano Lesson Made Easy第三冊","des-cn":"书本中的任何一首曲目"
            },
            6:{
            "id":6,"number":"P06", "name":"John Thompson Modern Course Grade One", "des":"Any 1 piece from the book", "price":50,"name-cn":"约翰·汤普森现代课程第一级","des-cn":"书本中的任何一首曲目"
            },
            7:{
            "id":7,"number":"P07", "name":"John Thompson Modern Course Grade Two", "des":"Any 1 piece from the book", "price":50,"name-cn":"约翰·汤普森现代课程第二级","des-cn":"书本中的任何一首曲目"
            },
            8:{
            "id":8,"number":"P08", "name":"Everybody Likes the Piano Elementary Book", "des":"Any 1 piece from the book", "price":50,"name-cn":"Everybody Loves The Piano - 初级","des-cn":"书本中的任何一首曲目"
            },
            9:{
            "id":9,"number":"P09", "name":"Everybody Likes the Piano Book 1", "des":"Any 1 piece from the book", "price":50,"name-cn":"Everybody Loves The Piano - 第一冊","des-cn":"书本中的任何一首曲目"
            },
            10:{
            "id":10,"number":"P10", "name":"Everybody Likes the Piano Book 2", "des":"Any 1 piece from the book", "price":50,"name-cn":"Everybody Loves The Piano - 第二冊","des-cn":"书本中的任何一首曲目"
            },
        },   
        3 : {			
            1:{
            "id":1,"number":"P31", "name":"Grade 1", "des":"Any 1 piece from the book",  "price":50,"name-cn":"第一级", "des-cn":"书本中的任何一首曲目"
            },
            2:{
            "id":2,"number":"P32", "name":"Grade 2	", "des":"Any 1 piece from the book", "price":50,"name-cn":"第二级", "des-cn":"书本中的任何一首曲目"
            },
            3:{
            "id":3,"number":"P33", "name":"Grade 3", "des":"Any 1 piece from the book","price":50,"name-cn":"第三级", "des-cn":"书本中的任何一首曲目"
            },
            4:{
            "id":4,"number":"P34", "name":"Grade 4","des":"Any 1 piece from the book", "price":50,"name-cn":"第四级", "des-cn":"书本中的任何一首曲目"
            },
        
        },
        2 : {			
            1:{
            "id":1,"number":"P21", "name":"Debut", "des":"7 or below; Any one piece less than 2 minutes",  "price":50, "name-cn":"Debut", "des-cn":"7岁或以下；任何一首少于 2 分钟的曲目"
            },
            2:{
            "id":2,"number":"P22", "name":"Junior", "des":"7 to 9; Any one piece less than 3 minutes", "price":50, "name-cn":"Junior", "des-cn":"7岁- 9岁；任何一首少于 3 分钟的曲目"
            },
            3:{
            "id":3,"number":"P23", "name":"Emerging", "des":"9 to 12; Any one piece less than 3 minutes","price":65, "name-cn":"Emerging", "des-cn":"9岁- 12岁；任何一首少于 3 分钟的曲目"
            },
            4:{
            "id":4,"number":"P24", "name":"Youth","des":"12 to 15; Any one piece less than 5 minutes", "price":65, "name-cn":"Youth", "des-cn":"12岁- 15岁；任何一首少于 5 分钟的曲目"
            },
            5:{
            "id":5,"number":"P25", "name":"Young Artist","des":"15 to 18; Any one piece less than 6 minutes", "price":75, "name-cn":"Young Artist", "des-cn":"15岁- 18岁；任何一首少于 6 分钟的曲目"
            },
            6:{
            "id":6,"number":"P26", "name":"Artist","des":"18 or above; Any one piece less than 12 minutes", "price":100, "name-cn":"Artist", "des-cn":"18岁或以上；任何一首少于 12 分钟的曲目"
            },
        
        },
        1 : {			
            1:{
            "id":1,"number":"P11", "name":"Grade 1", "des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus",  "price":50, "name-cn":"1 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            2:{
            "id":2,"number":"P12", "name":"Grade 2", "des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":50, "name-cn":"2 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            3:{
            "id":3,"number":"P13", "name":"Grade 3", "des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus","price":50, "name-cn":"3 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            4:{
            "id":4,"number":"P14", "name":"Grade 4","des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":65, "name-cn":"4 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            5:{
            "id":5,"number":"P15", "name":"Grade 5","des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":65, "name-cn":"5 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            6:{
            "id":6,"number":"P16", "name":"Grade 6","des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":65, "name-cn":"6 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            7:{
            "id":7,"number":"P17", "name":"Grade 7", "des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":75, "name-cn":"7 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            8:{
            "id":8,"number":"P18", "name":"Grade 8", "des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":75, "name-cn":"8 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            9:{
            "id":9,"number":"P19", "name":"ARSM, DipABRSM, ATCL", "des":"Any 1 piece from ARSM, DipABRSM, ATCL under 7 minutes", "price":100, "name-cn":"ARSM, DipABRSM, ATCL 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首7分钟内的曲目"
            },
            10:{
            "id":10,"number":"P110", "name":"LRSM, LTCL", "des":"Any 1 piece from LRSM/LTCL under 10 minutes", "price":115, "name-cn":"LRSM,LTCL 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首10分钟内曲目"
            },
            11:{
            "id":11,"number":"P111", "name":"FRSM,FTCL", "des":"Any 1 piece from FRSM/FTCL under 15 minutes", "price":130, "name-cn":"FRSM,FTCL 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首15钟内曲目"
            },
        
        },       
        4 : {			
            1:{
            "id":1,"number":"P41", "name":"Sonatina Group", "des":"Any 1 movement under 4 minutes, best player will receive Best Sonatina Performance Award",  "price":75, "name-cn":"小奏鸣曲组别", "des-cn":"4 分钟内任意 1 个乐章"
            },
            2:{
            "id":2,"number":"P42", "name":"Sonata Group", "des":"Any 1 movement under 8 minutes, best player will receive Best Sonata Performance Award", "price":90, "name-cn":"奏鸣曲组别", "des-cn":"8 分钟内任意 1 个乐章"
            },
            3:{
            "id":3,"number":"P43", "name":"Czerny Op 599 Group", "des":"Any 1 piece from the book","price":50, "name-cn":"Czerny Op 599 组别", "des-cn":"书中的任意一首曲目"
            },
            4:{
            "id":4,"number":"P44", "name":"Czerny Op 849 Group","des":"Any 1 piece from the book", "price":65, "name-cn":"Czerny Op 849 组别", "des-cn":"书中的任意一首曲目"
            },
            5:{
            "id":5,"number":"P45", "name":"Czerny Op 299 Group","des":"Any 1 piece from the book", "price":65, "name-cn":"Czerny Op 299 组别", "des-cn":"书中的任意一首曲目"
            },
            6:{
            "id":6,"number":"P46", "name":"Baroque Period Group","des":"Less than 10 minutes, best player will receive Best Baroque Music Performance Award", "price":90, "name-cn":"巴洛克时期组别","des-cn":"10分钟以內的一首曲目"
            },
            7:{
            "id":7,"number":"P47", "name":"Classical Period Group", "des":"Less than 10 minutes, best player will receive Best Classical Music Performance Award", "price":90, "name-cn":"古典时期组组别","des-cn":"10分钟以內的一首曲目"
            },
            8:{
            "id":8,"number":"P48", "name":"Romantic Period Group", "des":"Less than 10 minutes, best player will receive Best Romantic Music Performance Award", "price":90, "name-cn":"浪漫时期组别","des-cn":"10分钟以內的一首曲目"
            },
            9:{
            "id":9,"number":"P49", "name":"Modern Period Group", "des":"Less than 10 minutes, best player will receive Best Modern Music Performance Award", "price":90, "name-cn":"现代时期组别","des-cn":"10分钟以內的一首曲目"
            },
            10:{
            "id":10,"number":"P410", "name":"Chopin Etudes", "des":"Any 1 etude by Chopin, best player will receive Best Chopin Etude Performance Award", "price":100, "name-cn":"肖邦练习曲", "des-cn":"任何 1 首肖邦练习曲"
            },
            11:{
            "id":11,"number":"P411", "name":"Chopin Nocturne", "des":"Any 1 nocturne by Chopin, best player will receive Best Chopin Nocturne Performance Award", "price":100, "name-cn":"肖邦夜曲", "des-cn":"任何 1 首肖邦夜曲"
            },
            12:{
            "id":12,"number":"P412", "name":"Chopin Waltz", "des":"Any 1 waltz by Chopin, best player will receive Best Chopin Waltz Performance Award", "price":100, "name-cn":"肖邦圆舞曲", "des-cn":"任何 1 首肖邦圆舞曲"
            },
            // 13:{
            // "id":13,"number":"P413", "name":"Concerto", "des":"Any 1 movement under 12 minutes", "price":1000
            // },
            // 14:{
            // "id":14,"number":"P414", "name":"Composition", "des":"1 piece of original composition under 6 minutes", "price":800
            // },
        
        }
    },

    "strings" :{
        0 : {
            1:{
            "id":1,"number":"S01", "name":"Shinozaki - Violin Method 1", "des":"Any 1 piece from the book", "price":50, "name-cn":"筱崎 - 小提琴方法 1", "des-cn":"书中的任意一首"
            },
            2:{
            "id":2,"number":"S02", "name":"Shinozaki - Violin Method 2", "des":"Any 1 piece from the book", "price":50, "name-cn":"筱崎 - 小提琴方法 2", "des-cn":"书中的任意一首"
            }
        }, 
        3 : {},  
        2 : {			
            1:{
            "id":1,"number":"S21", "name":"Debut", "des":"7 or below; Any one piece less than 2 minutes",  "price":50, "name-cn":"Debut", "des-cn":"7岁或以下；任何一首少于 2 分钟的曲目"
            },
            2:{
            "id":2,"number":"S22", "name":"Junior	", "des":"7 to 9; Any one piece less than 3 minutes", "price":50, "name-cn":"Junior", "des-cn":"7岁 - 9岁；任何一首少于 3 分钟的曲目"
            },
            3:{
            "id":3,"number":"S23", "name":"Emerging", "des":"9 to 12; Any one piece less than 3 minutes","price":65, "name-cn":"Emerging", "des-cn":"9岁 - 12岁；任何一首少于 3 分钟的曲目"
            },
            4:{
            "id":4,"number":"S24", "name":"Youth","des":"12 to 15; Any one piece less than 5 minutes", "price":65, "name-cn":"Youth", "des-cn":"12岁 - 15岁；任何一首少于 5 分钟的曲目"
            },
            5:{
            "id":5,"number":"S25", "name":"Young Artist","des":"15 to 18; Any one piece less than 6 minutes", "price":75, "name-cn":"Young Artist", "des-cn":"15岁 - 18岁；任何一首少于 6 分钟的曲目"
            },
            6:{
            "id":6,"number":"S26", "name":"Artist","des":"18 or above; Any one piece less than 12 minutes", "price":100, "name-cn":"Artist", "des-cn":"18岁或以上；任何一首少于 12 分钟的曲目"
            },
        
        },
        1 : {			
            1:{
                "id":1,"number":"S11", "name":"Grade 1", "des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus",  "price":50, "name-cn":"1 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
                },
                2:{
                "id":2,"number":"S12", "name":"Grade 2", "des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":50, "name-cn":"2 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
                },
                3:{
                "id":3,"number":"S13", "name":"Grade 3", "des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus","price":50, "name-cn":"3 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
                },
                4:{
                "id":4,"number":"S14", "name":"Grade 4","des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":65, "name-cn":"4 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
                },
                5:{
                "id":5,"number":"S15", "name":"Grade 5","des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":65, "name-cn":"5 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
                },
                6:{
                "id":6,"number":"S16", "name":"Grade 6","des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":65, "name-cn":"6 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
                },
                7:{
                "id":7,"number":"S17", "name":"Grade 7", "des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":75, "name-cn":"7 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
                },
                8:{
                "id":8,"number":"S18", "name":"Grade 8", "des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":75, "name-cn":"8 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
                },
                9:{
                "id":9,"number":"S19", "name":"ARSM, DipABRSM, ATCL", "des":"Any 1 piece from ARSM, DipABRSM, ATCL under 7 minutes", "price":100, "name-cn":"ARSM, DipABRSM, ATCL 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首7分钟内的曲目"
                },
                10:{
                "id":10,"number":"S110", "name":"LRSM, LTCL", "des":"Any 1 piece from LRSM/LTCL under 10 minutes", "price":115, "name-cn":"LRSM,LTCL 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首10分钟内曲目"
                },
                11:{
                "id":11,"number":"S111", "name":"FRSM,FTCL", "des":"Any 1 piece from FRSM/FTCL under 15 minutes", "price":130, "name-cn":"FRSM,FTCL 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首15钟内曲目"
                },
            
        
        },       
        4 : {			
            1:{
            "id":1,"number":"S41", "name":"Sonata Group", "des":"Any 1 movement under 8 minutes, best player receives the Best Sonata Performance Award",  "price":90, "name-cn":"奏鸣曲组别", "des-cn":"8 分钟内任意 1 个乐章"
            },
            2:{
            "id":2,"number":"S42", "name":"Baroque Period Group", "des":"Any 1 piece less than 10 minutes, best player receives the Best Baroque Music Performance Award", "price":90, "name-cn":"巴洛克时期组别","des-cn":"10分钟以內的一首曲目"
            },
            3:{
            "id":3,"number":"S43", "name":"Classical Period Group", "des":"Any 1 piece less than 10 minutes, best player receives the Best Classical Music Performance Award","price":90, "name-cn":"古典时期组组别","des-cn":"10分钟以內的一首曲目"
            },
            4:{
            "id":4,"number":"S44", "name":"Romantic Period Group","des":"Any 1 piece less than 10 minutes, best player receives the Best Romantic Music Performance Award", "price":90, "name-cn":"浪漫时期组别","des-cn":"10分钟以內的一首曲目"
            },
            5:{
            "id":5,"number":"S45", "name":"Modern Period Group","des":"Any 1 piece less than 10 minutes, best player receives the Best Modern Music Performance Award", "price":90, "name-cn":"现代时期组别","des-cn":"10分钟以內的一首曲目"
            },
            6:{
            "id":6,"number":"S46", "name":"Etudes","des":"Any 1 etude under 6 minutes, best player receives the Best Etude Performance Award", "price":90, "name-cn":"练习曲", "des-cn":"任何 1 首6分钟以內练习曲"
            },
            // 7:{
            // "id":7,"number":"S47", "name":"Concerto", "des":"Any 1 movement under 12 minutes", "price":1000
            // },
            // 8:{
            // "id":8,"number":"S48", "name":"Composition", "des":"1 piece of original composition under 6 minutes", "price":800
            // },
        
        }
    },

    "vocal" :{
        0 : {},
        3 : {},   
        2 : {			
            1:{
            "id":1,"number":"V21", "name":"Debut", "des":"7 or below; Any one piece less than 2 minutes",  "price":50, "name-cn":"Debut", "des-cn":"7岁或以下；任何一首少于 2 分钟的曲目"
            },
            2:{
            "id":2,"number":"V22", "name":"Junior	", "des":"7 to 9; Any one piece less than 3 minutes", "price":50, "name-cn":"Junior", "des-cn":"7岁 - 9岁；任何一首少于 3 分钟的曲目"
            },
            3:{
            "id":3,"number":"V23", "name":"Emerging", "des":"9 to 12; Any one piece less than 3 minutes","price":65, "name-cn":"Emerging", "des-cn":"9岁 - 12岁；任何一首少于 3 分钟的曲目"
            },
            4:{
            "id":4,"number":"V24", "name":"Youth","des":"12 to 15; Any one piece less than 5 minutes", "price":65, "name-cn":"Youth", "des-cn":"12岁 - 15岁；任何一首少于 5 分钟的曲目"
            },
            5:{
            "id":5,"number":"V25", "name":"Young Artist","des":"15 to 18; Any one piece less than 6 minutes", "price":75, "name-cn":"Young Artist", "des-cn":"15岁 - 18岁；任何一首少于 6 分钟的曲目"
            },
            6:{
            "id":6,"number":"V26", "name":"Artist","des":"18 or above; Any one piece less than 12 minutes", "price":100, "name-cn":"Artist", "des-cn":"18或以上；任何一首少于 12 分钟的曲目"
            },
        
        },
        1 : {			
            1:{
                "id":1,"number":"V11", "name":"Grade 1", "des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus",  "price":50, "name-cn":"1 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
                },
                2:{
                "id":2,"number":"V12", "name":"Grade 2", "des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":50, "name-cn":"2 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
                },
                3:{
                "id":3,"number":"V13", "name":"Grade 3", "des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus","price":50, "name-cn":"3 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
                },
                4:{
                "id":4,"number":"V14", "name":"Grade 4","des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":65, "name-cn":"4 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
                },
                5:{
                "id":5,"number":"V15", "name":"Grade 5","des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":65, "name-cn":"5 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
                },
                6:{
                "id":6,"number":"V16", "name":"Grade 6","des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":65, "name-cn":"6 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
                },
                7:{
                "id":7,"number":"V17", "name":"Grade 7", "des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":75, "name-cn":"7 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
                },
                8:{
                "id":8,"number":"V18", "name":"Grade 8", "des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":75, "name-cn":"8 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
                },
                9:{
                "id":9,"number":"V19", "name":"ARSM, DipABRSM, ATCL", "des":"Any 1 piece from ARSM, DipABRSM, ATCL under 7 minutes", "price":100, "name-cn":"ARSM, DipABRSM, ATCL 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首7分钟内的曲目"
                },
                10:{
                "id":10,"number":"V110", "name":"LRSM, LTCL", "des":"Any 1 piece from LRSM/LTCL under 10 minutes", "price":115, "name-cn":"LRSM,LTCL 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首10分钟内曲目"
                },
                11:{
                "id":11,"number":"V111", "name":"FRSM,FTCL", "des":"Any 1 piece from FRSM/FTCL under 15 minutes", "price":130, "name-cn":"FRSM,FTCL 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首15钟内曲目"
                },
            
        
        },       
        4 : {			
            1:{
            "id":1,"number":"V41", "name":"Disney Song Group", "des":"Any 1 song less than 5 minutes", "price":75, "name-cn":"迪士尼歌曲组", "des-cn":"任何一首少于 5 分钟的歌曲"
            },
            2:{
            "id":2,"number":"V42", "name":"Bel Canto Group", "des":"Any 1 song less than 6 minutes","price":75, "name-cn":"美声歌曲组", "des-cn":"任何一首少于 6 分钟的歌曲"
            },
            3:{
            "id":3,"number":"V43", "name":"Pop Song Group","des":"Any 1 song less than 5 minutes", "price":75, "name-cn":"流行歌曲组","des-cn":"任何一首少于 5 分钟的歌曲"
            },
        }
    },

    "woodwind" :{
        0 : {
            1:{
            "id":1,"number":"W01", "name":"A Tune a Day Book 1", "des":"Any 1 piece from the book", "price":50, "name-cn":"A Tune a Day Book 1", "des-cn":"书中的任意一首"
            },
            2:{
            "id":2,"number":"W02", "name":"A Tune a Day Book 2", "des":"Any 1 piece from the book", "price":50, "name-cn":"A Tune a Day Book 2", "des-cn":"书中的任意一首"
            }
        }, 
        3 : {},  
        2 : {			
            1:{
            "id":1,"number":"W21", "name":"Debut", "des":"7 or below; Any one piece less than 2 minutes",  "price":50, "name-cn":"Debut", "des-cn":"7岁或以下；任何一首少于 2 分钟的曲目"
            },
            2:{
            "id":2,"number":"W22", "name":"Junior	", "des":"7 to 9; Any one piece less than 3 minutes", "price":50, "name-cn":"Junior", "des-cn":"7 - 9岁；任何一首少于 3 分钟的曲目"
            },
            3:{
            "id":3,"number":"W23", "name":"Emerging", "des":"9 to 12; Any one piece less than 3 minutes","price":65, "name-cn":"Emerging", "des-cn":"9 - 12岁；任何一首少于 3 分钟的曲目"
            },
            4:{
            "id":4,"number":"W24", "name":"Youth","des":"12 to 15; Any one piece less than 5 minutes", "price":65, "name-cn":"Youth", "des-cn":"12 - 15岁；任何一首少于 5 分钟的曲目"
            },
            5:{
            "id":5,"number":"W25", "name":"Young Artist","des":"15 to 18; Any one piece less than 6 minutes", "price":75, "name-cn":"Young Artist", "des-cn":"15 - 18岁；任何一首少于 6 分钟的曲目"
            },
            6:{
            "id":6,"number":"W26", "name":"Artist","des":"18 or above; Any one piece less than 12 minutes", "price":100, "name-cn":"Artist", "des-cn":"18岁或以上；任何一首少于 12 分钟的曲目"
            },
        
        },
        1 : {			
            1:{
            "id":1,"number":"W11", "name":"Grade 1", "des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus",  "price":50, "name-cn":"1 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            2:{
            "id":2,"number":"W12", "name":"Grade 2", "des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":50, "name-cn":"2 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            3:{
            "id":3,"number":"W13", "name":"Grade 3", "des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus","price":50, "name-cn":"3 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            4:{
            "id":4,"number":"W14", "name":"Grade 4","des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":65, "name-cn":"4 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            5:{
            "id":5,"number":"W15", "name":"Grade 5","des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":65, "name-cn":"5 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            6:{
            "id":6,"number":"W16", "name":"Grade 6","des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":65, "name-cn":"6 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            7:{
            "id":7,"number":"W17", "name":"Grade 7", "des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":75, "name-cn":"7 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            8:{
            "id":8,"number":"W18", "name":"Grade 8", "des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":75, "name-cn":"8 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            9:{
            "id":9,"number":"W19", "name":"ARSM, DipABRSM, ATCL", "des":"Any 1 piece from ARSM, DipABRSM, ATCL under 7 minutes", "price":100, "name-cn":"ARSM, DipABRSM, ATCL 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首7分钟内的曲目"
            },
            10:{
            "id":10,"number":"W110", "name":"LRSM, LTCL", "des":"Any 1 piece from LRSM/LTCL under 10 minutes", "price":115, "name-cn":"LRSM,LTCL 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首10分钟内曲目"
            },
            11:{
            "id":11,"number":"W111", "name":"FRSM,FTCL", "des":"Any 1 piece from FRSM/FTCL under 15 minutes", "price":130, "name-cn":"FRSM,FTCL 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首15钟内曲目"
            },
            
        
        },       
        4 : {			
            1:{
            "id":1,"number":"W41", "name":"Etudes", "des":"Any 1 etude/study under 6 minutes, best player receives Best Etude Performance Award",  "price":90, "name-cn":"练习曲", "des-cn":"任何 1 首6分钟以內练习曲"
            },
            // 2:{
            // "id":2,"number":"W42", "name":"Concerto", "des":"Any 1 movement less than 12 minutes", "price":130
            // },
            // 3:{
            // "id":3,"number":"W43", "name":"Composition", "des":"1 piece of original composition less than 6 minutes","price":800
            // },
        }
    },

    "brass" :{
        0 : {
            1:{
            "id":1,"number":"B01", "name":"A Tune a Day Book 1", "des":"Any 1 piece from the book", "price":45, "name-cn":"A Tune a Day Book 1", "des-cn":"书中的任意一首"
            },
            2:{
            "id":2,"number":"B02", "name":"A Tune a Day Book 2", "des":"Any 1 piece from the book", "price":45, "name-cn":"A Tune a Day Book 2", "des-cn":"书中的任意一首"
            }
        }, 
        3 : {},  
        2 : {			
            1:{
            "id":1,"number":"B21", "name":"Debut", "des":"7 or below; Any one piece less than 2 minutes",  "price":50,"name-cn":"Debut", "des-cn":"7岁或以下；任何一首少于 2 分钟的曲目"
            },
            2:{
            "id":2,"number":"B22", "name":"Junior	", "des":"7 to 9; Any one piece less than 3 minutes", "price":50, "name-cn":"Junior", "des-cn":"7 - 9岁；任何一首少于 3 分钟的曲目"
            },
            3:{
            "id":3,"number":"B23", "name":"Emerging", "des":"9 to 12; Any one piece less than 3 minutes","price":65, "name-cn":"Emerging", "des-cn":"9 - 12岁；任何一首少于 3 分钟的曲目"
            },
            4:{
            "id":4,"number":"B24", "name":"Youth","des":"12 to 15; Any one piece less than 5 minutes", "price":65, "name-cn":"Youth", "des-cn":"12 - 15岁；任何一首少于 5 分钟的曲目"
            },
            5:{
            "id":5,"number":"B25", "name":"Young Artist","des":"15 to 18; Any one piece less than 6 minutes", "price":75, "name-cn":"Young Artist", "des-cn":"15 - 18岁；任何一首少于 6 分钟的曲目"
            },
            6:{
            "id":6,"number":"B26", "name":"Artist","des":"18 or above; Any one piece less than 12 minutes", "price":100, "name-cn":"Artist", "des-cn":"18岁或以上；任何一首少于 12 分钟的曲目"
            },
        
        },
        1 : {			
            1:{
            "id":1,"number":"B11", "name":"Grade 1", "des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus",  "price":50, "name-cn":"1 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            2:{
            "id":2,"number":"B12", "name":"Grade 2", "des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":50, "name-cn":"2 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            3:{
            "id":3,"number":"B13", "name":"Grade 3", "des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus","price":50, "name-cn":"3 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            4:{
            "id":4,"number":"B14", "name":"Grade 4","des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":65, "name-cn":"4 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            5:{
            "id":5,"number":"B15", "name":"Grade 5","des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":65, "name-cn":"5 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            6:{
            "id":6,"number":"B16", "name":"Grade 6","des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":65, "name-cn":"6 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            7:{
            "id":7,"number":"B17", "name":"Grade 7", "des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":75, "name-cn":"7 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            8:{
            "id":8,"number":"B18", "name":"Grade 8", "des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":75, "name-cn":"8 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            9:{
            "id":9,"number":"B19", "name":"ARSM, DipABRSM, ATCL", "des":"Any 1 piece from ARSM, DipABRSM, ATCL under 7 minutes", "price":100, "name-cn":"ARSM, DipABRSM, ATCL 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首7分钟内的曲目"
            },
            10:{
            "id":10,"number":"B110", "name":"LRSM, LTCL", "des":"Any 1 piece from LRSM/LTCL under 10 minutes", "price":115, "name-cn":"LRSM,LTCL 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首10分钟内曲目"
            },
            11:{
            "id":11,"number":"B111", "name":"FRSM,FTCL", "des":"Any 1 piece from FRSM/FTCL under 15 minutes", "price":130, "name-cn":"FRSM,FTCL 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首15钟内曲目"
            },
        
        
        },       
        4 : {			
            1:{
            "id":1,"number":"B41", "name":"Etudes", "des":"Any 1 etude/study under 6 minutes, best player receives Best Etude Performance Award",  "price":90, "name-cn":"练习曲", "des-cn":"任何 1 首6分钟以內练习曲"
            },
            // 2:{
            // "id":2,"number":"B42", "name":"Concerto", "des":"Any 1 movement less than 12 minutes", "price":1000
            // },
            // 3:{
            // "id":3,"number":"B43", "name":"Composition", "des":"1 piece of original composition less than 6 minutes","price":800
            // },
        }
    },

    "harp":{
        0 : {}, 
        3 : {},  
        2 : {			
            1:{
            "id":1,"number":"H21", "name":"Debut", "des":"7 or below; Any one piece less than 2 minutes",  "price":50,"name-cn":"Debut", "des-cn":"7岁或以下；任何一首少于 2 分钟的曲目"
            },
            2:{
            "id":2,"number":"H22", "name":"Junior", "des":"7 to 9; Any one piece less than 3 minutes", "price":50, "name-cn":"Junior", "des-cn":"7 - 9岁；任何一首少于 3 分钟的曲目"
            },
            3:{
            "id":3,"number":"H23", "name":"Emerging", "des":"9 to 12; Any one piece less than 3 minutes","price":65, "name-cn":"Emerging", "des-cn":"9 - 12岁；任何一首少于 3 分钟的曲目"
            },
            4:{
            "id":4,"number":"H24", "name":"Youth","des":"12 to 15; Any one piece less than 5 minutes", "price":65, "name-cn":"Youth", "des-cn":"12 - 15岁；任何一首少于 5 分钟的曲目"
            },
            5:{
            "id":5,"number":"H25", "name":"Young Artist","des":"15 to 18; Any one piece less than 6 minutes", "price":75, "name-cn":"Young Artist", "des-cn":"15 - 18岁；任何一首少于 6 分钟的曲目"
            },
            6:{
            "id":6,"number":"H26", "name":"Artist","des":"18 or above; Any one piece less than 12 minutes", "price":100, "name-cn":"Artist", "des-cn":"18岁或以上；任何一首少于 12 分钟的曲目"
            },
        
        },
        1 : {			
            1:{
            "id":1,"number":"H11", "name":"Grade 1", "des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus",  "price":50, "name-cn":"1 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            2:{
            "id":2,"number":"H12", "name":"Grade 2", "des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":50, "name-cn":"2 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            3:{
            "id":3,"number":"H13", "name":"Grade 3", "des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus","price":50, "name-cn":"3 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            4:{
            "id":4,"number":"H14", "name":"Grade 4","des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":65, "name-cn":"4 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            5:{
            "id":5,"number":"H15", "name":"Grade 5","des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":65, "name-cn":"5 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            6:{
            "id":6,"number":"H16", "name":"Grade 6","des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":65, "name-cn":"6 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            7:{
            "id":7,"number":"H17", "name":"Grade 7", "des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":75, "name-cn":"7 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            8:{
            "id":8,"number":"H18", "name":"Grade 8", "des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":75, "name-cn":"8 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            9:{
            "id":9,"number":"H19", "name":"ARSM, DipABRSM, ATCL", "des":"Any 1 piece from ARSM, DipABRSM, ATCL under 7 minutes", "price":100, "name-cn":"ARSM, DipABRSM, ATCL 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首7分钟内的曲目"
            },
            10:{
            "id":10,"number":"H110", "name":"LRSM, LTCL", "des":"Any 1 piece from LRSM/LTCL under 10 minutes", "price":115, "name-cn":"LRSM,LTCL 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首10分钟内曲目"
            },
            11:{
            "id":11,"number":"H111", "name":"FRSM,FTCL", "des":"Any 1 piece from FRSM/FTCL under 15 minutes", "price":130, "name-cn":"FRSM,FTCL 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首15钟内曲目"
            },
        
        
        },       
        4 : {			
            1:{
            "id":1,"number":"H41", "name":"Etudes", "des":"Any 1 etude/study under 6 minutes, best player receives Best Etude Performance Award",  "price":90, "name-cn":"练习曲", "des-cn":"任何 1 首6分钟以內练习曲"
            },
            // 2:{
            // "id":2,"number":"H42", "name":"Concerto", "des":"Any 1 movement less than 12 minutes", "price":1000
            // },
            // 3:{
            // "id":3,"number":"H43", "name":"Composition", "des":"1 piece of original composition less than 6 minutes","price":800
            // },
        }
    },

    "recorder" :{
        0 : {}, 
        3 : {},  
        2 : {},
        1 : {			
            1:{
            "id":1,"number":"R11", "name":"Debut Group", "des":"Age 6-7; Any 1 piece less than 2 minutes",  "price":45,"name-cn":"Debut Group", "des-cn":"6 - 7岁； 任意 1 首 2 分钟以内的曲目"
            },
            2:{
            "id":2,"number":"R12", "name":"Junior Group", "des":"Age 7-9; Any 1 piece less than 2 minutes", "price":45,"name-cn":"Junior Group", "des-cn":"7 - 9岁； 任意 1 首 2 分钟以内的曲目"
            },
            3:{
            "id":3,"number":"R13", "name":"Emerging Group", "des":"Age 9-12; Any 1 piece less than 3 minutes","price":45,"name-cn":"Emerging Group", "des-cn":"9 - 12岁； 任意 1 首 3 分钟以内的曲目"
            },
            // 4:{
            // "id":4,"number":"R14", "name":"Primary 4","des":"Any 1 piece less than 2 minutes", "price":45
            // },
            // 5:{
            // "id":5,"number":"R15", "name":"Primary 5","des":"Any 1 piece less than 2 minutes", "price":45
            // },
            // 6:{
            // "id":6,"number":"R16", "name":"Primary 6","des":"Any 1 piece less than 2 minutes", "price":45
            // },
        },       
        4 : {}
    },

    "guitar":{
        0 : {}, 
        3 : {},  
        2 : {			
            1:{
            "id":1,"number":"G21", "name":"Debut", "des":"7 or below; Any one piece less than 2 minutes",  "price":50,"name-cn":"Debut", "des-cn":"7岁或以下；任何一首少于 2 分钟的曲目"
            },
            2:{
            "id":2,"number":"G22", "name":"Junior", "des":"7 to 9; Any one piece less than 3 minutes", "price":50, "name-cn":"Junior", "des-cn":"7 - 9岁；任何一首少于 3 分钟的曲目"
            },
            3:{
            "id":3,"number":"G23", "name":"Emerging", "des":"9 to 12; Any one piece less than 3 minutes","price":65, "name-cn":"Emerging", "des-cn":"9 - 12岁；任何一首少于 3 分钟的曲目"
            },
            4:{
            "id":4,"number":"G24", "name":"Youth","des":"12 to 15; Any one piece less than 5 minutes", "price":65, "name-cn":"Youth", "des-cn":"12 - 15岁；任何一首少于 5 分钟的曲目"
            },
            5:{
            "id":5,"number":"G25", "name":"Young Artist","des":"15 to 18; Any one piece less than 6 minutes", "price":75, "name-cn":"Young Artist", "des-cn":"15 - 18岁；任何一首少于 6 分钟的曲目"
            },
            6:{
            "id":6,"number":"G26", "name":"Artist","des":"18 or above; Any one piece less than 12 minutes", "price":100, "name-cn":"Artist", "des-cn":"18岁或以上；任何一首少于 12 分钟的曲目"
            },
        
        },
        1 : {			
            1:{
            "id":1,"number":"G11", "name":"Grade 1", "des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus",  "price":50, "name-cn":"1 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            2:{
            "id":2,"number":"G12", "name":"Grade 2", "des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":50, "name-cn":"2 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            3:{
            "id":3,"number":"G13", "name":"Grade 3", "des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus","price":50, "name-cn":"3 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            4:{
            "id":4,"number":"G14", "name":"Grade 4","des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":65, "name-cn":"4 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            5:{
            "id":5,"number":"G15", "name":"Grade 5","des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":65, "name-cn":"5 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            6:{
            "id":6,"number":"G16", "name":"Grade 6","des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":65, "name-cn":"6 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            7:{
            "id":7,"number":"G17", "name":"Grade 7", "des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":75, "name-cn":"7 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            8:{
            "id":8,"number":"G18", "name":"Grade 8", "des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":75, "name-cn":"8 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            9:{
            "id":9,"number":"G19", "name":"ARSM, DipABRSM, ATCL", "des":"Any 1 piece from ARSM, DipABRSM, ATCL under 7 minutes", "price":100, "name-cn":"ARSM, DipABRSM, ATCL 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首7分钟内的曲目"
            },
            10:{
            "id":10,"number":"G110", "name":"LRSM, LTCL", "des":"Any 1 piece from LRSM/LTCL under 10 minutes", "price":115, "name-cn":"LRSM,LTCL 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首10分钟内曲目"
            },
            11:{
            "id":11,"number":"G111", "name":"FRSM,FTCL", "des":"Any 1 piece from FRSM/FTCL under 15 minutes", "price":130, "name-cn":"FRSM,FTCL 级", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首15钟内曲目"
            },
        
        
        },       
        4 : {			
            1:{
            "id":1,"number":"G41", "name":"Etudes", "des":"Any 1 etude/study under 6 minutes, best player receives Best Etude Performance Award",  "price":90, "name-cn":"练习曲", "des-cn":"任何 1 首6分钟以內练习曲"
            },
            // 2:{
            // "id":2,"number":"G42", "name":"Concerto", "des":"Any 1 movement less than 12 minutes", "price":130
            // },
        }
    },

    "percussion":{
        0 : {}, 
        3 : {},  
        2 : {			
            1:{
            "id":1,"number":"K21", "name":"Debut - Indefinite Pitch", "des":"7 or below; Any one piece less than 2 minutes",  "price":50, "name-cn":"Debut - 不定音乐器", "des-cn":"7岁或以下；任何一首少于 2 分钟的曲目"
            },
            3:{
            "id":3,"number":"K23", "name":"Junior - Indefinite Pitch", "des":"7 to 9; Any one piece less than 3 minutes", "price":50, "name-cn":"Junior - 不定音乐器", "des-cn":"7 - 9岁；任何一首少于 3 分钟的曲目"
            },
            5:{
            "id":5,"number":"K25", "name":"Emerging - Indefinite Pitch", "des":"9 to 12; Any one piece less than 3 minutes","price":65, "name-cn":"Emerging - 不定音乐器", "des-cn":"9 - 12岁；任何一首少于 3 分钟的曲目"
            },
            7:{
            "id":7,"number":"K27", "name":"Youth - Indefinite Pitch","des":"12 to 15; Any one piece less than 5 minutes", "price":65, "name-cn":"Youth - 不定音乐器", "des-cn":"12 - 15岁；任何一首少于 5 分钟的曲目"
            },
            9:{
            "id":9,"number":"K29", "name":"Young Artist - Indefinite Pitch","des":"15 to 18; Any one piece less than 6 minutes", "price":75, "name-cn":"Young Artist - 不定音乐器", "des-cn":"12 - 15岁；任何一首少于 6 分钟的曲目"
            },
            11:{
            "id":11,"number":"K211", "name":"Artist - Indefinite Pitch","des":"18 or above; Any one piece less than 12 minutes", "price":100, "name-cn":"Artist - 不定音乐器", "des-cn":"12 - 15岁；任何一首少于 12 分钟的曲目"
            },
            2:{
            "id":2,"number":"K22", "name":"Debut - Definite Pitch", "des":"7 or below; Any one piece less than 2 minutes",  "price":50, "name-cn":"Debut - 定音乐器", "des-cn":"7岁或以下；任何一首少于 2 分钟的曲目"
            },
            4:{
            "id":4,"number":"K24", "name":"Junior - Definite Pitch", "des":"7 to 9; Any one piece less than 3 minutes", "price":50, "name-cn":"Junior - 定音乐器", "des-cn":"7 - 9岁；任何一首少于 3 分钟的曲目"
            },
            6:{
            "id":6,"number":"K26", "name":"Emerging - Definite Pitch", "des":"9 to 12; Any one piece less than 3 minutes","price":65, "name-cn":"Emerging - 定音乐器", "des-cn":"9 - 12岁；任何一首少于 3 分钟的曲目"
            },
            8:{
            "id":8,"number":"K28", "name":"Youth - Definite Pitch","des":"12 to 15; Any one piece less than 5 minutes", "price":65, "name-cn":"Youth - 定音乐器", "des-cn":"12 - 15岁；任何一首少于 5 分钟的曲目"
            },
            10:{
            "id":10,"number":"K210", "name":"Young Artist - Definite Pitch","des":"15 to 18; Any one piece less than 6 minutes", "price":75, "name-cn":"Young Artist - 定音乐器", "des-cn":"15 - 18岁；任何一首少于 6 分钟的曲目"
            },
            12:{
            "id":12,"number":"K212", "name":"Artist - Definite Pitch","des":"18 or above; Any one piece less than 12 minutes", "price":100, "name-cn":"Artist - 定音乐器", "des-cn":"18岁或以上；任何一首少于 12 分钟的曲目"
            },
        
        },
        1 : {			
            1:{
            "id":1,"number":"K11", "name":"Grade 1 - Indefinite Pitch", "des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus",  "price":50, "name-cn":"1 级 - 不定音乐器", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            3:{
            "id":3,"number":"K13", "name":"Grade 2 - Indefinite Pitch", "des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":50, "name-cn":"2 级 - 不定音乐器", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            5:{
            "id":5,"number":"K15", "name":"Grade 3 - Indefinite Pitch", "des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus","price":50, "name-cn":"3 级 - 不定音乐器", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            7:{
            "id":7,"number":"K17", "name":"Grade 4 - Indefinite Pitch","des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":65, "name-cn":"4 级 - 不定音乐器", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            9:{
            "id":9,"number":"K19", "name":"Grade 5 - Indefinite Pitch","des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":65, "name-cn":"5 级 - 不定音乐器", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            11:{
            "id":11,"number":"K111", "name":"Grade 6 - Indefinite Pitch","des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":65, "name-cn":"6 级 - 不定音乐器", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            13:{
            "id":13,"number":"K113", "name":"Grade 7 - Indefinite Pitch", "des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":75, "name-cn":"7 级 - 不定音乐器", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            15:{
            "id":15,"number":"K115", "name":"Grade 8 - Indefinite Pitch", "des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":75, "name-cn":"8 级 - 不定音乐器", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            17:{
            "id":17,"number":"K117", "name":"ARSM, DipABRSM, ATCL - Indefinite Pitch", "des":"Any 1 piece from ARSM, DipABRSM, ATCL under 7 minutes", "price":100, "name-cn":"ARSM, DipABRSM, ATCL 级 - 不定音乐器", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首7分钟内的曲目"
            },
            19:{
            "id":19,"number":"K119", "name":"LRSM, LTCL - Indefinite Pitch", "des":"Any 1 piece from LRSM/LTCL under 10 minutes", "price":115, "name-cn":"LRSM,LTCL 级 - 不定音乐器", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首10分钟内的曲目"
            },
            21:{
            "id":21,"number":"K121", "name":"FRSM,FTCL - Indefinite Pitch", "des":"Any 1 piece from FRSM/FTCL under 15 minutes", "price":130, "name-cn":"FRSM,FTCL 级 - 不定音乐器", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首15分钟内的曲目"
            },

            2:{
            "id":2,"number":"K12", "name":"Grade 1 - Definite Pitch", "des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus",  "price":50, "name-cn":"1 级 - 定音乐器", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            4:{
            "id":4,"number":"K14", "name":"Grade 2 - Definite Pitch", "des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":50, "name-cn":"8 级 - 定音乐器", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            6:{
            "id":6,"number":"K16", "name":"Grade 3 - Definite Pitch", "des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus","price":50, "name-cn":"8 级 - 定音乐器", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            8:{
            "id":8,"number":"K18", "name":"Grade 4 - Definite Pitch","des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":65, "name-cn":"8 级 - 定音乐器", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            10:{
            "id":10,"number":"K110", "name":"Grade 5 - Definite Pitch","des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":65, "name-cn":"8 级 - 定音乐器", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            12:{
            "id":12,"number":"K112", "name":"Grade 6 - Definite Pitch","des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":65, "name-cn":"8 级 - 定音乐器", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            14:{
            "id":14,"number":"K114", "name":"Grade 7 - Definite Pitch", "des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":75, "name-cn":"8 级 - 定音乐器", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            16:{
            "id":16,"number":"K116", "name":"Grade 8 - Definite Pitch", "des":"Any 1 piece from the ABRSM/Trinity Graded Exam Syllabus", "price":75, "name-cn":"8 级 - 定音乐器", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首曲目"
            },
            18:{
            "id":18,"number":"K118", "name":"ARSM, DipABRSM, ATCL - Definite Pitch", "des":"Any 1 piece from ARSM, DipABRSM, ATCL under 7 minutes", "price":100, "name-cn":"ARSM, DipABRSM, ATCL 级 - 定音乐器", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首7分钟内的曲目"
            },
            20:{
            "id":20,"number":"K120", "name":"LRSM, LTCL - Definite Pitch", "des":"Any 1 piece from LRSM/LTCL under 10 minutes", "price":115, "name-cn":"LRSM,LTCL 级 - 定音乐器", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首10分钟内的曲目"
            },
            22:{
            "id":22,"number":"K122", "name":"FRSM,FTCL - Definite Pitch", "des":"Any 1 piece from FRSM/FTCL under 15 minutes", "price":130, "name-cn":"FRSM, FTCL 级 - 定音乐器", "des-cn":"ABRSM/Trinity 分级考试課程中的任意 1 首15分钟内的曲目"
            },
        
        },       
        4 : {			
            1:{
            "id":1,"number":"K41", "name":"Etudes - Indefinite Pitch", "des":"Any 1 etude/study under 6 minutes, best player receives Best Etude Performance Award",  "price":90, "name-cn":"练习曲 - 不定音乐器", "des-cn":"任何 1 首6分钟以內练习曲"
            },
            2:{
                "id":2,"number":"K42", "name":"Etudes - Indefinite Pitch", "des":"Any 1 etude/study under 6 minutes, best player receives Best Etude Performance Award",  "price":90, "name-cn":"练习曲 - 定音乐器", "des-cn":"任何 1 首6分钟以內练习曲"
                },
            3:{
                "id":3,"number":"K43", "name":"Pop Music", "des":"Any 1 pop music part written for drumset or cajon under 5 minutes",  "price":65, "name-cn":"流行音乐", "des-cn":"为鼓组或箱鼓编写的任何 1 个流行音乐部分，时长在 5 分钟内"
                },
        }
    },
    "orchestra":{
        0:{},
        1:{},
        2:{},
        3:{},
        4 : {			
            1:{
            "id":1,"number":"OR1", "name":"Orchestra Primary Group", "des":"All members (except conductor) age under 13; any 1 piece or movement for orchestra under 20 minutes",  "price":250, "name-cn":"练习曲 - 不定音乐器", "des-cn":"任何 1 首6分钟以內练习曲"
            },
            2:{
                "id":2,"number":"OR2", "name":"Orchestra Secondary Group", "des":"All members (except conductor) age under 18; any 1 piece or movement for orchestra under 20 minutes",  "price":250, "name-cn":"练习曲 - 定音乐器", "des-cn":"任何 1 首6分钟以內练习曲"
                },
            3:{
                "id":3,"number":"OR3", "name":"Orchestra Open Group", "des":"Members of all ages; any 1 piece or movement for orchestra under 20 minutes",  "price":250, "name-cn":"流行音乐", "des-cn":"为鼓组或箱鼓编写的任何 1 个流行音乐部分，时长在 5 分钟内"
                },
            4:{
                "id":4,"number":"BA1", "name":"Concert Band Primary Group", "des":"All members (except conductor) age under 13; any 1 piece or movement for concert band under 20 minutes",  "price":250, "name-cn":"练习曲 - 不定音乐器", "des-cn":"任何 1 首6分钟以內练习曲"
                },
            5:{
                "id":5,"number":"BA2", "name":"Concert Band Secondary Group", "des":"All members (except conductor) age under 18; any 1 piece or movement for concert band under 20 minutes",  "price":250, "name-cn":"练习曲 - 定音乐器", "des-cn":"任何 1 首6分钟以內练习曲"
                },
            6:{
                "id":6,"number":"BA3", "name":"Concert Band Open Group", "des":"Members of all ages; any 1 piece or movement for concert band under 20 minutes",  "price":250, "name-cn":"流行音乐", "des-cn":"为鼓组或箱鼓编写的任何 1 个流行音乐部分，时长在 5 分钟内"
                },
        }
    },

    "special":{
        0 : {}, 
        3 : {},  
        2 : {			
        },
        1 : {			
        },       
        4 : {			
            1:{
                "id":1,"number":"Z41", "name":"Concerto", "des":"Any 1 movement less than 12 minutes, best player receives the Best Concerto Performance Award",  "price":130, "name-cn":"协奏曲", "des-cn":"任何少于 12 分钟的 1 个乐章",
                },
            2:{
                "id":2,"number":"Z42", "name":"Composition", "des":"Submit 1 piece of original composition under 8 minutes, best player receives the Best Composition Award",  "price":80, "name-cn":"作曲", "des-cn":"提交1首8分钟以内的原创作品"
                },
            3:{
                "id":3,"number":"Z43", "name":"World Music", "des":"Any 1 traditional piece originally written for the instrument",  "price":50, "name-cn":"世界音乐", "des-cn":"任何一首最初为该乐器创作的传统作品"
                },
            4:{
                "id":4,"number":"Z44", "name":"Ensemble", "des":"Any 1 piece or 1 movement less than 8 minutes for an ensemble made up from any instruments less than 6 people",  "price":100,"name-cn":"合奏组", "des-cn":"由少于 6 人的任何乐器组成的合奏团，任何 1 件作品或 1 个乐章，时间不超过 8 分钟"
                },
        }
    },

}

export default groupCat;