const pricelist = {
    "P01": 50,
    "P02": 50,
    "P03": 50,
    "P04": 50,
    "P05": 50,
    "P06": 50,
    "P07": 50,
    "P08": 50,
    "P09": 50,
    "P10": 50,
    "P31": 50,
    "P32": 50,
    "P33": 50,
    "P34": 50,
    "P21": 50,
    "P22": 50,
    "P23": 65,
    "P24": 65,
    "P25": 75,
    "P26": 100,
    "P11": 50,
    "P12": 50,
    "P13": 50,
    "P14": 65,
    "P15": 65,
    "P16": 65,
    "P17": 75,
    "P18": 75,
    "P19": 100,
    "P110": 115,
    "P111": 130,
    "P41": 75,
    "P42": 90,
    "P43": 50,
    "P44": 65,
    "P45": 65,
    "P46": 90,
    "P47": 90,
    "P48": 90,
    "P49": 90,
    "P410": 100,
    "P411": 100,
    "P412": 100,
    // "P413": 130,
    // "P414": 100,

    "S01": 50,
    "S02": 50,
    "S21": 50,
    "S22": 50,
    "S23": 65,
    "S24": 65,
    "S25": 75,
    "S26": 100,
    "S11": 50,
    "S12": 50,
    "S13": 50,
    "S14": 65,
    "S15": 65,
    "S16": 65,
    "S17": 75,
    "S18": 75,
    "S19": 100,
    "S110": 115,
    "S111": 130,
    "S41": 90,
    "S42": 90,
    "S43": 90,
    "S44": 90,
    "S45": 90,
    "S46": 90,
    // "S47": 1000,
    // "S48": 800,

    "V21": 50,
    "V22": 50,
    "V23": 65,
    "V24": 65,
    "V25": 75,
    "V26": 100,
    "V11": 50,
    "V12": 50,
    "V13": 50,
    "V14": 65,
    "V15": 65,
    "V16": 65,
    "V17": 75,
    "V18": 75,
    "V19": 100,
    "V110": 115,
    "V111": 130,
    "V41": 75,
    "V42": 75,
    "V43": 75,
    // "V44": 75,

    "W01": 50,
    "W02": 50,
    "W21": 50,
    "W22": 50,
    "W23": 65,
    "W24": 65,
    "W25": 75,
    "W26": 100,
    "W11": 50,
    "W12": 50,
    "W13": 50,
    "W14": 65,
    "W15": 65,
    "W16": 65,
    "W17": 75,
    "W18": 75,
    "W19": 100,
    "W110": 115,
    "W111": 130,
    "W41": 90,
    // "W42": 1000,
    // "W43": 800,

    "B01": 45,
    "B02": 45,
    "B21": 50,
    "B22": 50,
    "B23": 65,
    "B24": 65,
    "B25": 75,
    "B26": 100,
    "B11": 50,
    "B12": 50,
    "B13": 50,
    "B14": 65,
    "B15": 65,
    "B16": 65,
    "B17": 75,
    "B18": 75,
    "B19": 100,
    "B110": 115,
    "B111": 130,
    "B41": 90,
    // "B42": 1000,
    // "B43": 800,

    "H21": 50,
    "H22": 50,
    "H23": 65,
    "H24": 65,
    "H25": 75,
    "H26": 100,
    "H11": 50,
    "H12": 50,
    "H13": 50,
    "H14": 65,
    "H15": 65,
    "H16": 65,
    "H17": 75,
    "H18": 75,
    "H19": 100,
    "H110": 115,
    "H111": 130,
    "H41": 90,

    "G21": 50,
    "G22": 50,
    "G23": 65,
    "G24": 65,
    "G25": 75,
    "G26": 100,
    "G11": 50,
    "G12": 50,
    "G13": 50,
    "G14": 65,
    "G15": 65,
    "G16": 65,
    "G17": 75,
    "G18": 75,
    "G19": 100,
    "G110": 115,
    "G111": 130,
    "G41": 90,
   
    "R11":45,
    "R12":45,
    "R13":45,
    // "R14":45,
    // "R15":45,
    // "R16":45,

    "K21": 50,
    "K22": 50,
    "K23": 50,
    "K24": 50,
    "K25": 65,
    "K26": 65,
    "K27": 65,
    "K28": 65,
    "K29": 75,
    "K210": 75,
    "K211": 100,
    "K212": 100,
    "K11": 50,
    "K12": 50,
    "K13": 50,
    "K14": 50,
    "K15": 50,
    "K16": 50,
    "K17": 65,
    "K18": 65,
    "K19": 65,
    "K110": 65,
    "K111": 65,
    "K112": 65,
    "K113": 75,
    "K114": 75,
    "K115": 75,
    "K116": 75,
    "K117": 100,
    "K118": 100,
    "K119": 115,
    "K120": 115,
    "K121": 130,
    "K122": 130,
    "K41": 90,
    "K42": 90,
    "K43": 65,

    "Z41":130,
    "Z42":80,
    "Z43":50,
    "Z44":100,


    "IYAOTESTPAYMENT":0.01,
}

export default pricelist;