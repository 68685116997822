import React, { Component } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import {Modal, Button } from 'react-bootstrap';

const clientID = "Af8Aj6tTR-c3k05rk_h-ydVdphrYKy-rn96xdDVeUI_kzcNstfvfv_i1UY8Pq8eJugH05P0HatYp2iW9" //real
//const clientID = "AYjS8JJEeQjpxiAmTyDqOJANSOJgxgmjuf4IPFRIqmqsu0oZlrGZ-vz0NB5p86pfNcf55-zEWpznAy4O" //test
class AwardCheckout extends Component {
  constructor(props){
    super(props);
    this.state = {
       paidFor:false,
       showModal:false,
       error:null,
       finalCur:null,
       finalPrice:null,
    };

    this.setval = this.setval.bind(this)
    this.handleApprove = this.handleApprove.bind(this)
    this.setError = this.setError.bind(this)
    this.closeModal = this.closeModal.bind(this)

  }

  setval(obj){
    this.setState(obj)
  }
  handleApprove(orderID){

    this.setState({paidFor:true,showModal:true})
  }
  setError(string){
    this.setState({error:string})
  }
  closeModal(){
    this.setState({showModal:false,paidFor:false,error:null})
  }
  componentDidMount(){
    //   if(document.getElementById(this.props.pagepos)){
    //     document.getElementById(this.props.pagepos).scrollIntoView();
    //   }   
  }      
      
  render(){
    if(this.state.paidFor){
        //alert("Thank you. Please take a screenshot of the payment details below:\n Applicant Name: "+this.props.applicantName+"\n Group applied: "+this.props.chosengroup+ "\n Amount paid (USD): "+this.props.USDprice);
      }
      if(this.state.error===true && this.props.productDescription==null){
        alert("Please input the correct information.")
        this.setState({error:null})
        //alert(this.state.error);
      }
    return (
        <PayPalScriptProvider options={{'client-id':clientID,
                                          'locale':"en_HK",
                                            //currency:this.state.chosencurrency,
                                          }}>

             <Modal show={this.state.showModal} onHide={this.closeModal}>
                    <Modal.Header closeButton>
                    <Modal.Title>Payment Summary</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>{"Congratulations to you!! Thank you for joining the IYAO Competition. Please also fill in the shipment form to complete your application."}</p>
                        <p>{"Please take a screenshot of the payment details below:"}</p>
                        {/* <p>{"Applicant Name: "+this.props.applicantName}</p> */}
                        
                        <p>{"Amount paid: "+this.props.awardPayment}</p>
                        <p>{"Current time: "+new Date().toLocaleString("en-US")}</p>
                        </Modal.Body>
                    <Modal.Footer>
                    <Button variant="primary" onClick={this.closeModal}>
                        Close
                    </Button>

                    </Modal.Footer>
                </Modal>

                <Modal show={this.state.showError} onHide={this.closeModal}>
                    <Modal.Header closeButton>
                    <Modal.Title>Payment failed: Error occured.</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{"Payment failed. Please provide the correct information or contact our administration for help."}</Modal.Body>
                    <Modal.Footer>
                    <Button variant="primary" onClick={this.closeModal}>
                        Close
                    </Button>

                    </Modal.Footer>
                </Modal>

      <PayPalButtons
        onClick={(data,actions)=>{
          const alreadyBought = false;
          if(alreadyBought){
            this.setError("You already paid");
            return actions.reject();
          } else {
            return actions.resolve();
          }
        }}
        createOrder={(data,actions)=>{
          return actions.order.create({
            purchase_units:[
              {
                description: this.props.productDescription,
                amount: {
                  value: this.props.awardPayment,
                //   currency_base: "HKD"
                },
              },
            ],
          });
        }}
        
        onApprove = {async (data,action)=>{
          const order = await action.order.capture();
          const curr = order.purchase_units[0].amount.currency_code
          const value = order.purchase_units[0].amount.value
          console.log("order",order);

          this.handleApprove(data.orderID);
          this.setState({finalCur:curr,finalPrice:value})
        }}

        onCancel ={()=>{}}
        onError = {(err)=>{
          this.setError(err);
          console.log("Paypal checkout onError", err);
        }}

        />

    </PayPalScriptProvider>
    );
  }
  }
  

export default AwardCheckout;
