import '../../App.css';
import React, {Component, Container} from 'react';
import {Image,Navbar, Nav, NavItem, NavDropdown} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeBackground from 'react-youtube-background';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import NavBarTop from "./navbar";
import Footer from "./footer";
const applyurl = "https://docs.google.com/forms/d/e/1FAIpQLScoSIrXM_CqsXcaw6mSZmB8f6E1MoXcis3QFeRaE_SO6cP_5A/viewform?usp=sf_link"

class Jury extends Component {
  constructor(props){
    super(props);
    this.state = {  
       
    };

    this.setval = this.setval.bind(this)
  }
  componentDidMount(){
    document.title="International Young Artist Open Competition"
    var link1 = document.getElementById('favicon')
    var link2 = document.getElementById('touchicon')
    link1.href="/assets/iyao_logo.png"
    link2.href="/assets/iyao_logo.png"
    this.props.setval({comp:"iyao"})
  }
  setval(obj){
    this.setState(obj)
  }

  render(){
    return (
      <div>
       {/* <img src="/assets/term.png" width="100%" style={{borderRadius:"0px"}} /> */}
      <div style={{display:"flex",flexDirection:"column",paddingLeft:"15%",paddingRight:"15%",paddingTop:"3%",paddingBottom:"2%",textAlign:"left"}}>
      <h4 style={{fontSize:"1.8em",textAlign:"center",marginBottom:"100px"}}>Current and Past Jury</h4>

      <div style={{display:'block', marginBottom:"100px"}}>
        <div className="jury-profile">
            <img src="/assets/andy.jpg" alt="" width="100%" />
            <h4 style={{marginTop:"5px"}}><b>Professor Andreas Stier</b></h4>
            <h5><em>Pianist and Educationist</em></h5>
            </div>
        <div style={{position:"absolute"}}>
            <img className="jury-flag" alt="" src="/assets/germany-flag.png" />
        </div>
        <div className="jury-text" style={{marginLeft:"4%"}}>
            <p>Andreas Stier was born in Hannover(Germany) in 1952, Through his very musical family he received many kinds of
                inspirations from early childhood - piano music, chamber music, especially vocal music. Wherever he studied in Germany, Korea, Taiwan,
                he joined choirs, school orchestras (violin), and was engaged in chamber music or vocal accompaniment (piano).
            </p>
                <p>In spring 1983 he graduated from Hamburg University majoring in Sinology with a Master of Arts degree. 
                    In autumn 1983 he graduated from HfMT (University of Music and Theatre) Hamburg, majoring in piano with the teacher's diploma.
                    In autumn 1983 he began to teach as a professor for Piano at the HfMT Hamburg.
            </p>
            <p>Within 38 years of working at the HfMT he also taught many students from China, Korea and Taiwan, trying to bring them closer to the mentality of
                western music and composers. During the last eight years he also taught Japanese students at a piano academy in Hamburg.
            </p>
            <p>Since 1978 Andreas Stier ha been teaching private students, some of them preparing for the entrance examination to the Music University, 
                others participating in music competitions. He has been a jury member of the official German music youth competition "Jugend Musiziert" for solo piano
                and piano chamber music for more than three decades.</p>
            <p>For Andreas Stier playing music has always been a means of communication and a wonderful chance to understand and overcome cultural distances. In 1978 he married his Taiwanese wife. 
                They have three children and three grandchildren.</p>
        </div>
    </div>

    <div style={{display:'block', marginBottom:"100px"}}>
        <div className="jury-text" style={{marginLeft:"0px",marginRight:"4%"}}>
            <p> 
            Dominic Sargent’s musical career began at the age of 6 when he joined the choir
            of Bangor Cathedral in Wales, and started piano lessons. After winning an Organ
            Scholarship to Trinity Hall, Cambridge University, he continued his musical
            studies with Frederik Prausnitz, and graduated with a Doctor of Musical Arts in
            conducting from Peabody Conservatory in the United States. </p>
            <p>Dr Sargent has travelled widely in Europe and Asia, teaching, composing, and performing. He is
            known as a highly effective practitioner. His untiring advocacy for the performing
            arts has brought him many rewarding and enriching experiences which he hopes
            to share with young musicians all over the world. </p>
        </div>

        <div className="jury-profile" style={{position:"relative"}}>
        <div style={{position:"absolute"}}>
            <img className="jury-flag" alt="" src="/assets/uk-flag.png"/></div>
            <img src="/assets/dominic.jpg" alt=""  width="100%"/>
            <h4 style={{marginTop:"5px"}}><b>Dr. Dominic Sargent</b></h4>
            <h5><em>Conductor and Music Educationist</em></h5>
            </div>
        
        

    </div>

    <div style={{display:'block', marginBottom:"100px"}}>
        <div className="jury-profile">
            <img src="/assets/julia.jpg" alt="" width="100%"/>
            <h4 style={{marginTop:"5px"}}><b>Professor Julia Bushkova</b></h4>
            <h5><em>Director of the Ensemble "Violunti"</em></h5>
            </div>
        <div style={{position:"absolute"}}>
            <img className="jury-flag" alt="" src="/assets/usa-flag.jpg" /></div>
        <div className="jury-text">
            <p>
            Acclaimed by Joseph Gingold as a "virtuoso of the highest rank," Julia Bushkova has appeared as a soloist with numerous orchestras and in recital throughout the United States, 
            in her native Russia, as well as Germany, Italy, Canada, United Kingdom, Poland, South Africa, Mexico, Chile, and Venezuela. "Schwarzwalder Bote" (Germany) described her playing as "a Russian violin tradition of highest elegance." 
            The "Grand Rapids Press" and the "Ann Arbor News" emphasized "her remarkable expressiveness and an unusually large and beautiful tone... her dazzling cadenzas and extraordinary technical execution."</p>
            <p>Julia Bushkova has been a featured performer on WBLV and WIAA Public Radio, WGVU-TV (Michigan), WFMT (Chicago), KOTM-FM and KGRS-FM (Iowa), WRUV-FM (Vermont), WRR Classical 101.1 (Dallas), CBS Public Radio (Canada) as well as nationwide on NPR's Performance Today.
            Her most recent solo and concerti performances took her to Moscow, Saint-Petersburg (Russia), Minsk (Belarus) New York, North Carolina, Mississippi, Oklahoma, Michigan, Canada, South Africa, Italy, and Venezuela.</p>
            <p>Born into a family of renowned violinists in Moscow, Russia, Julia Bushkova began study of the violin at the age of five and made her concerto debut at the age of fifteen in Poland. 
            She graduated with highest honors (summa cum laude) from the Moscow Tchaikovsky Conservatory where she studied with Professors: Igor Bezrodny, Zoria Shikhmurzaeva, Konstantin Adjemov, and Dmitri Shebalin of the Borodin String Quartet. 
            During this time, she performed in Hungary, Poland, Bulgaria, and the former USSR, including concerts in Moscow's Great Hall and the Palace of Congress.</p>
            </div>
    </div>

    <div style={{display:'block', marginBottom:"100px"}}>

        <div className="jury-text" style={{marginLeft:"0px",marginRight:"4%"}}>
            <p>
            “The Russian pianist and composer, Arsentiy Kharitonov, proved to be the newest addition to the top ranks of this season’s artists,” said The New York Observer, citing his Carnegie Hall debut as one of the top six concerts of the season. 
            Fanfare Magazine writes, “Arsentiy Kharitonov’s sumptuous tone, technical mastery, broad dramatic range, and elegant phrasing place him in the top ranks of keyboard players today. Above this and trumping most of the musical gymnasts of the day, 
            there is genuine poetry here, the ring of truth, and, it seems to me, a rare sincerity issuing from a deep place in the pianist’s heart.”
            </p><p>
            Hailing from St. Petersburg, Arsentiy Kharitonov is first-prize winner of the Franz Liszt International Piano Competition (US). After also winning the Rachmaninov Competition (Russian Federation), he began his concert career with engagements throughout Europe including appearances at Philharmonic Hall, 
            and the Mariinsky Theater in St. Petersburg, at the Royal Conservatory, Stockholm, and the Sibelius Academy in Helsinki, followed by Asian tours with performances in the major halls of, among others, Shanghai, Beijing, and Seoul. On this continent, 
            he has appeared—among others—at the Music and Beyond Festival in Ottawa, at the National Gallery of Art, DC, with orchestra at the Tabernacle at Temple in the Square, Salt Lake City, at Steinway Hall, The Frick Collection, and Carnegie Hall in NYC.
       </p>
       <p>
       The Observer wrote of the latter concert, “…his style is unlike any of his contemporaries and harkens back to earlier times. The dynamic range had an astonishing breadth, and the lyric passages were especially captivating… This was a performance to remember.”
        </p></div>

       <div className="jury-profile" style={{position:"relative"}}>
            <div style={{position:"absolute"}}>
                <img className="jury-flag" alt="" src="/assets/russia-flag.png" /></div>
            <img src="/assets/arsentiy.jpg" alt=""  width="100%"/>
            <h4 style={{marginTop:"5px"}}><b>Professor Arsentiy Kharitonov</b></h4>
            <h5><em>Concert Pianist and Composer</em></h5>
            </div>
      

    </div>

    <div style={{display:'block', marginBottom:"100px"}}>
        <div className="jury-profile">
            <img src="/assets/abraham.png" alt=""  width="100%"/>
            <h4 style={{marginTop:"5px"}}><b>Professor Abraham Tena Manrique</b></h4>
            <h5><em>President of the World Piano Teacher Association (Spain) - Composition</em></h5>
            </div>
        <div style={{position:"absolute"}}>
            <img className="jury-flag" alt="" src="/assets/spain-flag.png" /></div>
        <div className="jury-text"><p>
            As the President of World Piano Teachers Association-SPAIN-COMPOSITION, Socio numerario de Federacion de Asociaciones Ibericas de Compositores, Soci numerari de l'Associacio Catalana de Compositors, and Socio de la Sociedad General de Autores y Editores, 
            professor Abraham Tena Manrique is active in the composing field. In 2021 and 2022 he was the guest composer at two international competitions and festivals, WPTA Spain and MIMAS Music Festival Competition. Many contestants performed his compositions. 
            In both competitions a financial Scholarship was awared to the best performer of one of his compositions. In 2021 the <i>Da Vinci</i> publishing house concluded the ambitious project of publishing all the works composed by Prof. Manrique, resulting in 7 beautiful 
            volumes that include works for piano, piano 4 hands, the concerto for piano and orchestra Op. 42, the oratorio 'The seven last words of Christ on the Cross' Op.40, chamber works with clarinet and a lied. This news were also published in the extensive interview
            in the magazine Ritmo of January 2022 that finalized the creative notion initiated since 2017.
       </p>
       <p>In April 2018, Prof. Manrique was appointed the President of the WPTA-SPAIN-COMPOSITION (World Piano Teachers Association). The WPTA is an association that exists in more than 30 countries, where it annually organizes conferences, piano, compositions, and chamber
        music competitions, as well as recitals and masterclasses. Prof. Manrique was the President of the Court of the I International Composition Contest of the WPTA-SPAIN in 2019. Bewteen 2017 and 2018, Prof. Manrique offered various recitals where he performed the 18 works for piano
        that made up the CD published by <i>Da Vinci</i> with several of his works performed by himself.
       </p>
       <p>Without neglecting its concert facet, it is also worth mentioning the Closing Concert offered at the III Edition of the Villa de Xabia International Festival (Alicante), invited by Nati Ballarin, director and founder of the institution. Interested in the composer's piano works, she created
        the 'Abraham Tena Manrique' award for the VI Edition of the Villa de Xabia International Piano Competition - of which she was also a founder and director then in 2018.
       </p>
       <p>Prof. Manrique's work, in addition to his interpretive and editorial aspects, also include works such as the Cantata Las Siete Words de Cristo en la Cruz commissioned by Sergio Espejo, pianist of the National Orchestra and Choir of Spain (OCNE). It was present at one of the Satellite concerts
        orgainzed annually by the OCNE in the Madrid Auditorium, where the composer performed his Seneca Octet Op. Classic (RNE) in March 1, 2016.
       </p>
        <p> Recently, Maestro Tena Manrique has made the recording of all his solo piano compositions for the Japanese record label, <i>Da Vinci</i>. Recordings made in the <i>Barcelona Auditorium</i> counting for the long session a <i>Steinway Gran Cola D274</i> thanks to Pianoservei. 
        50 works that will appear on 3 CDs in a few months. In autumn-winter of 2023 he will offer some recitals to present many of the compositions. Prof. Manrique is currently the President of the WPTA-SPAIN-COMPOSITION. 
        The WPTA is an association around the world, where it organizes annual conferences, piano-playing competitions, composition and chamber music, as well as recitals and master-classes.</p></div>
    </div>


    <div style={{display:'block', marginBottom:"100px"}}>
        
        <div className="jury-text" style={{marginLeft:"0px",marginRight:"4%"}}>
        <p>
           A native of the United States, Melissa Sorohan currently resides in Hong Kong where she is performer and vocal instructor.
        </p>
        <p>Melissa graduated from Cleveland Institute of Music in Cleveland, Ohio, USA, and soon after began teaching in a suburban music school. In 2012 she was appointed to Adjunt Instructor of Voice at Malone University in Canton, Ohio
            where she continued to teach until 2017.
        </p>
        <p>During this time she completed her Master of Music degree in Voice from The University of Akron in Akron, Ohio. In 2017 she moved to Hong Kong to take a voice teaching position at M.Int Academy Hong Kong. She currently is a Crew Performer with World Family Club.</p>
        <p>During her time studying, Melissa has had the opportunity to sing in Italy and Switzerland through participation in the Sessione Senese per La Musica e L'Arte, based out of Siena, Italy. She was also a participant in the Resonanz Opera summer program (Willoughby, Ohio) 
            and participated in master classes through Nightingale Opera Theatre (Hudson, Ohio).</p>
        <p>While living in Cleveland, Melissa was a regular gigging musician, singing with Great Lakes Light Opera and also maintaining choral positions with various churches and synagogues. On stage, she performed the role of Mother in <i>Amahl</i> and the <i>Night Visitors</i>, Baba in <i>The Medium</i>,
        and Announcer in <i>Gallantry</i>, all with the The University of Akron Opera Theatre. Melissa also had the pleasure of performing alongside her students in various Malone Opera Theatre productions. She performed La Zia Principessa in <i>Suor Angelica</i>, Marcellina in <i>Le Nozze Di Figaro</i>, 
        and First Lady in <i>Die Zauberflote</i>. She was most recently seen as Madame Desroches in <i>A certain Madness</i> with Nightingale Opera Theatre.</p>
        <p>Melissa is currently a busy performer in Hong Kong, working in children's theatre with World Family Club and Dove Tails Theatre. She also acts and performs regularly with the murder mystery troupe, Harbour Secrets. She recently also had the pleasure of emceeing the Women of Hong Kong First Anniversary Party.
            Melissa also sings in a pop/rock cover band called Seaside Arms and continues to sing classically with the St. John's Cathedral Evensong Choir, the Hong Kong Bach Choir, and the Cecilian Singers.</p>
        </div>

        <div className="jury-profile" style={{position:"relative"}}>
            <div style={{position:"absolute"}}>
                <img className="jury-flag" alt=""  src="/assets/usa-flag.jpg" /></div>
            <img src="/assets/melissa.png"  alt="" width="100%"/>
            <h4 style={{marginTop:"5px"}}><b>Ms Melissa Sorohan</b></h4>
            <h5><em>Opera Singer and University Vocal Instructor</em></h5>
            </div>
            
    </div>



    <div style={{display:'block', marginBottom:"100px"}}>
        <div className="jury-profile">
            <img src="/assets/antonio.jpg" alt=""  width="100%"/>
            <h4 style={{marginTop:"5px"}}><b>Dr. Antonio Frank Jardilino Maciel</b></h4>
            <h5><em>Principal Trombone and Music Educationist</em></h5>
            </div>
        <div style={{position:"absolute"}}>
            <img className="jury-flag" alt="" src="/assets/italy-flag.png" /></div>
        <div className="jury-text"><p>
           Frank began his musical studies with his brother at the age of 6, who taught him the slide trombone and euphonium. He played for many years with the "Dona Luiza Tavora" Band, at the "Piamarta" College, in Fortaleza (Brazil),
           where he had the opportunity to participate in various international wind orchestra competitions.
           </p>
           <p>Later Frank came to Europe, where he studied philosophy at the Pontifical Salesian University and his Postgraduation in Musicotherapy.</p>
            <p>Frank also studied at <i>the Santa Cecilia Foundation</i> in Brescia (Italy), where he studied piano with M. Paderni, and Gregorian M. Longhini, composition with G. Facchinetti.
            He started to study trombone at the Conservatory "Luca Marenzio" in Brescia, where he graduated (bachelor and master's degree) in trombone with the eminent G. Delmonte in 2007.</p>
            <p>He worked as solo trombone in the orchestra "I solisti Europei" and as an integrant of the "Mascoulisse" trombone quartet. He won the first trombone audition at the "Orchestra Sinfonica di Pescara" 
                where he worked in a non-stable running in important programs. He also collaborates with the "Societa Filarmonica di Udine" and "Orchestra Regionale Marchigiana" during several opers seasons.
            </p>
            <p>Frank won the audition for the company "Stage Entertainment-Italy", where he was the principal Trombone and Tuba and where he played at "Mamma Mia", "Beauty and the Beast" and Sister Act (seasons 2009,2010 and 2011). 
                In 2010, 2011, 2012 he was honor's guest of the Ceara Government State (Brazil), who invited him to the 2nd, 3rd and 4th "Forum dos trombonistas Cearences" and where he taught in various master classes.</p>
            <p>In these years he also wrote the Educational Project for the new "Bachelor" course in trombone in the Seara State: program written for the leading universities at the same state. This project is currently depositied at the 
                "SECITECE" (SECRETARIAT DE SCIENCE AND TECHNOLOGY) and it was being approved and applied. In 2012 he participated at the "Festival of Brazilian trombonists" and at the first symposium of ABT (Brazilian trombonists association) as a special guest.
            </p>
            <p>In 2011 he graduated for his "Diploma accademico di Il livello (D.M.A)" at the "Istituo Musicale Pareggiato della Valle d'Aostoa- Italy", with research about the Brazilian context and Brazilian popular music, graduating with honors.</p>
            <p>In that 2 years he had the opportunity to study with: Stefano Viola (Free lance and teacher), Toby Oft(Boston Symphony Orchestra), Andrea Bandini (Suisse Romande), Vincet Lepape(Teatro Reggio Torino), Giuseppe Grandi (Teatro Alla Scala), Fabiano Fiorenzani 
                (Maggio Fiorentino), Ian Bousfield (Vienna Philharmonic), Massimo La Rosa (Cleveland Symphony Orchestra), Stefan Schulz (Berlin Philharmonic).
            </p>
            <p>Frank develops an intense didactic and musical activity in Europe, Brazil and Middle East. He was Trombone's Teacher at "Faculdade Tecnica Darcy Ribeiro" - Fortaleza Brazil - in 2011/2012 and Brass Teacher at the "Edward Said National Conservatory and Birzeit University"
                from September 2013 until May 2014.
            </p>
            <p>Frank also obtained the diploma of high studies (doctoral level) in 2015 at the "Universidade de Evora" in Portugal with a thesis on Brazilian music. In November 2017 he obtained the master's degree in Philosophical Sciences at the University of Chieti-Pescara "G.D. Annunzio"
                with a thesis on Hegel and Kant according to the interpretation of Catherine Malabou.
            </p>
            <p>In 2019 he edited and published with Mimesis a translation of some writings by Catherine Malabou under the title <i>Avvenire e dolore trascendentale</i> and with Meltemi <i>Divenire Forma. Epigenesi e razionalita</i> of the same author.
            In addition, he is enrolled in the PhD in Italian studies at Sorbonne Universite with research that is placed between different areas such as that of biology, neurobiology, neurosciences, and art forms (such as music).</p>
            <p>He is now Principal Trombone and Soloist at the <i>Corelli Chamber Orchestra</i> and integral member of Chorocarioca quintet.</p>
            </div>
    </div>

    
    <div style={{display:'block', marginBottom:"100px"}}>
       
        <div className="jury-text" style={{marginLeft:"0px",marginRight:"4%"}}><p>
            Alexandre Teheux was born in Paris in 1959. After private lessons with Federic Delvaux, he began musical studies at Academie Gretry in Liege with Jeanne Bernard-Knapen.</p>
            <p>He studied later in Liege Conservatory with Chantal Bohets and Marcelle Mercenier. He also learnt accompaniment with Robert Bleser, harmony with Berthe Di Vito-Delvaux, practical harmony with Guy Luypaerts,
                chamber music with Cecile Evrard and Jean-Pierre Peuvion.
            </p>
            <p>In 1987-1988, a grant from Belgian government gave him the possibility to receive, in Chopin Academy in Warsaw, lessons from Regina Smendzianka, Andrzej Dutkiewicz and Janusz Grobelny.</p>
            <p>He regularly returns there for a summer course, where he finds the atmosphere and culture of Chopin's country.</p>
            <p>Accompainist, then piano teacher at Academie Gretry, Alexandre Teheux continues to perform in concerts, notably with the violinist Benoit Paquay.</p>
            </div>

        <div className="jury-profile" style={{position:"relative"}}>
            <div style={{position:"absolute"}}>
                <img className="jury-flag" alt="" src="/assets/belgium-flag.jpg" /></div>
            <img src="/assets/alexandre.jpg" alt=""  width="100%"/>
            <h4 style={{marginTop:"5px"}}><b>Mr. Alexandre Teheux</b></h4>
            <h5><em>Pianist and Music Educationist</em></h5>
        </div>

    </div>

      </div>
      </div>
    );
  }
  }
  

export default Jury;
