const iyfaaoPrice = {

   "PT1":35,
   "PT2":35,
   "PT3":35,
   "PT4":40,
   "PT5":45,
   "PT6":45,

   "PR1":35,
   "PR2":35,
   "PR3":35,
   "PR4":40,
   "PR5":45,
   "PR6":45,

   "DS1":35,
   "DS2":35,
   "DS3":35,
   "DS4":40,
   "DS5":45,
   "DS6":45,

   "DD1":35,
   "DD2":35,
   "DD3":35,
   "DD4":40,
   "DD5":45,
   "DD6":45,

   "DG1":35,
   "DG2":35,
   "DG3":35,
   "DG4":40,
   "DG5":45,
   "DG6":45,

   "ST1":35,
   "ST2":35,
   "ST3":35,
   "ST4":40,
   "ST5":45,
   "ST6":45,

   "PS1":35,
   "PS2":35,
   "PS3":35,
   "PS4":40,
   "PS5":45,
   "PS6":45,


"TEST":0.01,
}

export default iyfaaoPrice;