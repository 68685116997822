import '../../App.css';
import React, {Component} from 'react';
import {Row, Col, Button,Container, NavItem } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import YoutubeBackground from 'react-youtube-background';

import NavBarTop from "./navbar";
import Footer from "./footer";
import iesadCat from './iesad-cat';

class IESADCategory extends Component {
  constructor(props){
    super(props);
    this.state = {  
      group: iesadCat,
      chosenTab:"Poetry",
    };

    this.changeGroup = this.changeGroup.bind(this)
    this.setval = this.setval.bind(this)
  }
  componentDidMount(){
    document.title="International English Speech Art and Drama Competition"
    var link1 = document.getElementById('favicon')
    var link2 = document.getElementById('touchicon')
    link1.href="/assets/iesad_logo.png"
    link2.href="/assets/iesad_logo.png"
    this.props.setval({comp:"iesad"})
  }
  setval(obj){
    this.setState(obj)
  }
  changeGroup(val){
   const _group = JSON.parse(JSON.stringify(iesadCat))
   //alert(JSON.stringify(_group))
   this.setState({group:_group,chosenTab:val})
  }
  render(){
    return (
      <div className="category-div">
        <video loop autoPlay muted id="myVideo">
          <source
            src="../assets/category.mp4"
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        
        <div id="cat-content"> 
          {/* <button className={[(this.state.chosenTab=="Colouring")?"cat-button-selected":"cat-button"].join(" ")} 
            style={{fontWeight:"bold",marginBottom:"7px"}} onClick={(e)=>{this.changeGroup("Colouring")}}>Colouring</button> */}
          <button className={[(this.state.chosenTab=="Poetry")?"cat-button-selected":"cat-button"].join(" ")} 
             style={{fontWeight:"bold",marginBottom:"7px"}} onClick={(e)=>{this.changeGroup("Poetry")}}>Poetry</button>
          <button className={[(this.state.chosenTab=="Prose")?"cat-button-selected":"cat-button"].join(" ")} 
             style={{fontWeight:"bold",marginBottom:"7px"}} onClick={(e)=>{this.changeGroup("Prose")}}>Prose</button>
          <button className={[(this.state.chosenTab=="Dramatic Performance")?"cat-button-selected":"cat-button"].join(" ")} 
             style={{fontWeight:"bold",marginBottom:"7px"}} onClick={(e)=>{this.changeGroup("Dramatic Performance")}}>Dramatic Performance</button>
              <button className={[(this.state.chosenTab=="Storytelling")?"cat-button-selected":"cat-button"].join(" ")} 
             style={{fontWeight:"bold",marginBottom:"7px"}} onClick={(e)=>{this.changeGroup("Storytelling")}}>Storytelling</button>
              <button className={[(this.state.chosenTab=="Public Speaking")?"cat-button-selected":"cat-button"].join(" ")} 
             style={{fontWeight:"bold",marginBottom:"7px"}} onClick={(e)=>{this.changeGroup("Public Speaking")}}>Public Speaking</button>
          {/* <button className={[(this.state.instrument=="brass")?"cat-button-selected":"cat-button"].join(" ")} 
             style={{fontWeight:"bold",marginBottom:"7px"}} onClick={(e)=>{this.changeGroup("brass")}}>Brass</button>
           */}
        </div>
        <Container fluid style={{padding:"2% 5%",fontFamily:"Courgette",position:"relative",zIndex:"2"}} >

          {/* {
            (this.state.chosenTab==="Colouring") && 
            <div style={{display:"flex",flexDirection:"column",justifyContent:"left"}}>
              <p>Colouring is a beginner category for pre-school artists. Simply:</p>
              <p>1. Download the template from our website.</p>
              <p>2. Colour the drawing to your liking.</p>
              <p>3. Scan and send the finished artwork to our email.</p>
            </div>
          } */}

          { (this.state.chosenTab!=="Dramatic Performance") &&
             
            <div style={{margin:"15px 5px",border:"1px solid white",display:"flex",flexDirection:"column",alignItems:"center"}}>
            
             <div style={{width:"100%",justifyContent:"left",display:"flex",paddingLeft:"3%",fontSize:"1.5rem",border:"1px solid white"}}>
               {this.state.chosenTab}
             </div>
             <div style={{display:"flex",flexDirection:"row",justifyContent:"space-around",width:"100%",borderBottom:"1px solid rgba(255,255,255,0.5)"}}>
               <div className={["iyfao-cat-cell"].join(" ")}>Group Number</div>
               <div className={["iyfao-cat-cell"].join(" ")}>Group Name</div>
               <div className={["iyfao-cat-cell"].join(" ")}>Description</div>
               <div className={["iyfao-cat-cell"].join(" ")}>{"Price (USD)"}</div>
             </div>
               <div style={{display:"flex",flexDirection:"row",justifyContent:"space-around",width:"100%",borderBottom:"1px solid rgba(255,255,255,0.2)"}}>
                 <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][1]["number"]}</div>
                 <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][1]["name"]}</div>
                 <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][1]["des"]}</div>
                 <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][1]["price"]}</div>
               </div>
               <div style={{display:"flex",flexDirection:"row",justifyContent:"space-around",width:"100%",borderBottom:"1px solid rgba(255,255,255,0.2)"}}>
                 <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][2]["number"]}</div>
                 <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][2]["name"]}</div>
                 <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][2]["des"]}</div>
                 <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][2]["price"]}</div>
               </div>
               <div style={{display:"flex",flexDirection:"row",justifyContent:"space-evenly",width:"100%",borderBottom:"1px solid rgba(255,255,255,0.2)"}}>
                 <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][3]["number"]}</div>
                 <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][3]["name"]}</div>
                 <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][3]["des"]}</div>
                 <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][3]["price"]}</div>
               </div>
               <div style={{display:"flex",flexDirection:"row",justifyContent:"space-evenly",width:"100%",borderBottom:"1px solid rgba(255,255,255,0.2)"}}>
                 <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][4]["number"]}</div>
                 <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][4]["name"]}</div>
                 <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][4]["des"]}</div>
                 <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][4]["price"]}</div>
               </div>
               <div style={{display:"flex",flexDirection:"row",justifyContent:"space-evenly",width:"100%",borderBottom:"1px solid rgba(255,255,255,0.2)"}}>
                 <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][5]["number"]}</div>
                 <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][5]["name"]}</div>
                 <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][5]["des"]}</div>
                 <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][5]["price"]}</div>
               </div>
               {/* <div style={{display:"flex",flexDirection:"row",justifyContent:"space-evenly",width:"100%",borderBottom:"1px solid rgba(255,255,255,0.2)"}}>
                 <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][6]["number"]}</div>
                 <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][6]["name"]}</div>
                 <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][6]["des"]}</div>
                 <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][6]["price"]}</div>
               </div> */}

          
         </div>
          }

            
          { (this.state.chosenTab==="Dramatic Performance") &&
            Object.keys(this.state.group[this.state.chosenTab]).map((item,key)=>{
              
                return <div key={key} style={{margin:"15px 5px",border:"1px solid white",display:"flex",flexDirection:"column",alignItems:"center"}}>
                    <div style={{width:"100%",justifyContent:"left",display:"flex",paddingLeft:"3%",fontSize:"1.5rem",border:"1px solid white"}}>
                      {item}
                    </div>
                    <div style={{display:"flex",flexDirection:"row",justifyContent:"space-around",width:"100%",borderBottom:"1px solid rgba(255,255,255,0.5)"}}>
                      <div className={["iyfao-cat-cell"].join(" ")}>Group Number</div>
                      <div className={["iyfao-cat-cell"].join(" ")}>Group Name</div>
                      <div className={["iyfao-cat-cell"].join(" ")}>Description</div>
                      <div className={["iyfao-cat-cell"].join(" ")}>{"Price (USD)"}</div>
                    </div>
                      <div style={{display:"flex",flexDirection:"row",justifyContent:"space-around",width:"100%",borderBottom:"1px solid rgba(255,255,255,0.2)"}}>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][item][1]["number"]}</div>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][item][1]["name"]}</div>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][item][1]["des"]}</div>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][item][1]["price"]}</div>
                      </div>
                      <div style={{display:"flex",flexDirection:"row",justifyContent:"space-around",width:"100%",borderBottom:"1px solid rgba(255,255,255,0.2)"}}>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][item][2]["number"]}</div>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][item][2]["name"]}</div>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][item][2]["des"]}</div>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][item][2]["price"]}</div>
                      </div>
                      <div style={{display:"flex",flexDirection:"row",justifyContent:"space-evenly",width:"100%",borderBottom:"1px solid rgba(255,255,255,0.2)"}}>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][item][3]["number"]}</div>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][item][3]["name"]}</div>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][item][3]["des"]}</div>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][item][3]["price"]}</div>
                      </div>
                      <div style={{display:"flex",flexDirection:"row",justifyContent:"space-evenly",width:"100%",borderBottom:"1px solid rgba(255,255,255,0.2)"}}>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][item][4]["number"]}</div>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][item][4]["name"]}</div>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][item][4]["des"]}</div>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][item][4]["price"]}</div>
                      </div>
                      <div style={{display:"flex",flexDirection:"row",justifyContent:"space-evenly",width:"100%",borderBottom:"1px solid rgba(255,255,255,0.2)"}}>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][item][5]["number"]}</div>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][item][5]["name"]}</div>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][item][5]["des"]}</div>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][item][5]["price"]}</div>
                      </div>
                      {/* <div style={{display:"flex",flexDirection:"row",justifyContent:"space-evenly",width:"100%",borderBottom:"1px solid rgba(255,255,255,0.2)"}}>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][item][6]["number"]}</div>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][item][6]["name"]}</div>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][item][6]["des"]}</div>
                        <div className={["iyfao-cat-cell"].join(" ")}>{this.state.group[this.state.chosenTab][item][6]["price"]}</div>
                      </div> */}

                   
                  </div>
              
            })
          }

          </Container>

      </div>
    );
  }
  }
  

export default IESADCategory;
