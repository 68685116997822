import iyfaaoPrice from "./pricelist"; 

const iyfaoGroupCat = {
    "Painting" :{
        "Watercolor" : {
                1:{
                "id":1,"number":"PW1", "name":"Debut", "des":"Age 7 or below",  "price":iyfaaoPrice["PW1"]
                },
                2:{
                "id":2,"number":"PW2", "name":"Junior	", "des":"Age 7 to 9", "price":iyfaaoPrice["PW2"]
                },
                3:{
                "id":3,"number":"PW3", "name":"Emerging", "des":"Age 9 to 12","price":iyfaaoPrice["PW3"]
                },
                4:{
                "id":4,"number":"PW4", "name":"Youth","des":"Age 12 to 15", "price":iyfaaoPrice["PW4"]
                },
                5:{
                "id":5,"number":"PW5", "name":"Young Artist","des":"Age 15 to 18", "price":iyfaaoPrice["PW5"]
                },
                6:{
                "id":6,"number":"PW6", "name":"Artist","des":"Age 18 or above", "price":iyfaaoPrice["PW6"]
                },
        },   
        "Pastel" : {			
            1:{
                "id":1,"number":"PP1", "name":"Debut", "des":"Age 7 or below",  "price":iyfaaoPrice["PP1"]
                },
                2:{
                "id":2,"number":"PP2", "name":"Junior	", "des":"Age 7 to 9", "price":iyfaaoPrice["PP2"]
                },
                3:{
                "id":3,"number":"PP3", "name":"Emerging", "des":"Age 9 to 12","price":iyfaaoPrice["PP3"]
                },
                4:{
                "id":4,"number":"PP4", "name":"Youth","des":"Age 12 to 15", "price":iyfaaoPrice["PP4"]
                },
                5:{
                "id":5,"number":"PP5", "name":"Young Artist","des":"Age 15 to 18", "price":iyfaaoPrice["PP5"]
                },
                6:{
                "id":6,"number":"PP6", "name":"Artist","des":"Age 18 or above", "price":iyfaaoPrice["PP6"]
                },
        },
        "Ink Wash" : {			
            1:{
                "id":1,"number":"PI1", "name":"Debut", "des":"Age 7 or below",  "price":iyfaaoPrice["PI1"]
                },
                2:{
                "id":2,"number":"PI2", "name":"Junior	", "des":"Age 7 to 9", "price":iyfaaoPrice["PI2"]
                },
                3:{
                "id":3,"number":"PI3", "name":"Emerging", "des":"Age 9 to 12","price":iyfaaoPrice["PI3"]
                },
                4:{
                "id":4,"number":"PI4", "name":"Youth","des":"Age 12 to 15", "price":iyfaaoPrice["PI4"]
                },
                5:{
                "id":5,"number":"PI5", "name":"Young Artist","des":"Age 15 to 18", "price":iyfaaoPrice["PI5"]
                },
                6:{
                "id":6,"number":"PI6", "name":"Artist","des":"Age 18 or above", "price":iyfaaoPrice["PI6"]
                },
        
        },
        "Sketch" : {			
            1:{
                "id":1,"number":"PS1", "name":"Debut", "des":"Age 7 or below",  "price":iyfaaoPrice["PS1"]
                },
                2:{
                "id":2,"number":"PS2", "name":"Junior	", "des":"Age 7 to 9", "price":iyfaaoPrice["PS2"]
                },
                3:{
                "id":3,"number":"PS3", "name":"Emerging", "des":"Age 9 to 12","price":iyfaaoPrice["PS3"]
                },
                4:{
                "id":4,"number":"PS4", "name":"Youth","des":"Age 12 to 15", "price":iyfaaoPrice["PS4"]
                },
                5:{
                "id":5,"number":"PS5", "name":"Young Artist","des":"Age 15 to 18", "price":iyfaaoPrice["PS5"]
                },
                6:{
                "id":6,"number":"PS6", "name":"Artist","des":"Age 18 or above", "price":iyfaaoPrice["PS6"]
                },
        
        },       
        "Oil" : {			
            1:{
                "id":1,"number":"PO1", "name":"Debut", "des":"Age 7 or below",  "price":iyfaaoPrice["PO1"]
                },
                2:{
                "id":2,"number":"PO2", "name":"Junior	", "des":"Age 7 to 9", "price":iyfaaoPrice["PO2"]
                },
                3:{
                "id":3,"number":"PO3", "name":"Emerging", "des":"Age 9 to 12","price":iyfaaoPrice["PO3"]
                },
                4:{
                "id":4,"number":"PO4", "name":"Youth","des":"Age 12 to 15", "price":iyfaaoPrice["PO4"]
                },
                5:{
                "id":5,"number":"PO5", "name":"Young Artist","des":"Age 15 to 18", "price":iyfaaoPrice["PO5"]
                },
                6:{
                "id":6,"number":"PO6", "name":"Artist","des":"Age 18 or above", "price":iyfaaoPrice["PO6"]
                },
        },
        "Acrylic" : {			
            1:{
                "id":1,"number":"PA1", "name":"Debut", "des":"Age 7 or below",  "price":iyfaaoPrice["PA1"]
                },
                2:{
                "id":2,"number":"PA2", "name":"Junior	", "des":"Age 7 to 9", "price":iyfaaoPrice["PA2"]
                },
                3:{
                "id":3,"number":"PA3", "name":"Emerging", "des":"Age 9 to 12","price":iyfaaoPrice["PA3"]
                },
                4:{
                "id":4,"number":"PA4", "name":"Youth","des":"Age 12 to 15", "price":iyfaaoPrice["PA4"]
                },
                5:{
                "id":5,"number":"PA5", "name":"Young Artist","des":"Age 15 to 18", "price":iyfaaoPrice["PA5"]
                },
                6:{
                "id":6,"number":"PA6", "name":"Artist","des":"Age 18 or above", "price":iyfaaoPrice["PA6"]
                },
        }
    },

    "Theme" :{
        "My Homeland (Any material and style)" : {
            1:{
                "id":1,"number":"TE1", "name":"Debut", "des":"Age 7 or below",  "price":iyfaaoPrice["TE1"]
                },
                2:{
                "id":2,"number":"TE2", "name":"Junior	", "des":"Age 7 to 9", "price":iyfaaoPrice["TE2"]
                },
                3:{
                "id":3,"number":"TE3", "name":"Emerging", "des":"Age 9 to 12","price":iyfaaoPrice["TE3"]
                },
                4:{
                "id":4,"number":"TE4", "name":"Youth","des":"Age 12 to 15", "price":iyfaaoPrice["TE4"]
                },
                5:{
                "id":5,"number":"TE5", "name":"Young Artist","des":"Age 15 to 18", "price":iyfaaoPrice["TE5"]
                },
                6:{
                "id":6,"number":"TE6", "name":"Artist","des":"Age 18 or above", "price":iyfaaoPrice["TE6"]
                },
        },  
        // "Travelling" : {			
        //     1:{
        //         "id":1,"number":"TT1", "name":"Debut", "des":"Age 7 or below",  "price":55
        //         },
        //         2:{
        //         "id":2,"number":"TT2", "name":"Junior	", "des":"Age 7 to 9", "price":55
        //         },
        //         3:{
        //         "id":3,"number":"TT3", "name":"Emerging", "des":"Age 9 to 12","price":55
        //         },
        //         4:{
        //         "id":4,"number":"TT4", "name":"Youth","des":"Age 12 to 15", "price":65
        //         },
        //         5:{
        //         "id":5,"number":"TT5", "name":"Young Artist","des":"Age 15 to 18", "price":65
        //         },
        //         6:{
        //         "id":6,"number":"TT6", "name":"Artist","des":"Age 18 or above", "price":75
        //         },
        
        // },
        // "Dream" : {			
        //     1:{
        //         "id":1,"number":"TD1", "name":"Debut", "des":"Age 7 or below",  "price":55
        //         },
        //         2:{
        //         "id":2,"number":"TD2", "name":"Junior	", "des":"Age 7 to 9", "price":55
        //         },
        //         3:{
        //         "id":3,"number":"TD3", "name":"Emerging", "des":"Age 9 to 12","price":55
        //         },
        //         4:{
        //         "id":4,"number":"TD4", "name":"Youth","des":"Age 12 to 15", "price":65
        //         },
        //         5:{
        //         "id":5,"number":"TD5", "name":"Young Artist","des":"Age 15 to 18", "price":65
        //         },
        //         6:{
        //         "id":6,"number":"TD6", "name":"Artist","des":"Age 18 or above", "price":75
        //         },
        
        // },       
        
    },

    "Colouring" :{
        "Preschool Group" : {
            1:{
                "id":1,"number":"C1", "name":"Debut", "des":"Age 4 or below",  "price":47
                },
                2:{
                "id":2,"number":"C2", "name":"Junior	", "des":"Age 4 to 5", "price":47
                },
                3:{
                "id":3,"number":"C3", "name":"Emerging", "des":"Age 5 to 8","price":47
                },
            
        },
       
    },

    "Sculpturing" :{
        "Ceremics" : {
            1:{
                "id":1,"number":"SC1", "name":"Debut", "des":"Age 7 or below",  "price":iyfaaoPrice["SC1"]
                },
                2:{
                "id":2,"number":"SC2", "name":"Junior	", "des":"Age 7 to 9", "price":iyfaaoPrice["SC2"]
                },
                3:{
                "id":3,"number":"SC3", "name":"Emerging", "des":"Age 9 to 12","price":iyfaaoPrice["SC3"]
                },
                4:{
                "id":4,"number":"SC4", "name":"Youth","des":"Age 12 to 15", "price":iyfaaoPrice["SC4"]
                },
                5:{
                "id":5,"number":"SC5", "name":"Young Artist","des":"Age 15 to 18", "price":iyfaaoPrice["SC5"]
                },
                6:{
                "id":6,"number":"SC6", "name":"Artist","des":"Age 18 or above", "price":iyfaaoPrice["SC6"]
                },
        }, 
        "Handcraft" : {			
            1:{
                "id":1,"number":"SH1", "name":"Debut", "des":"Age 7 or below",  "price":iyfaaoPrice["SH1"]
                },
                2:{
                "id":2,"number":"SH2", "name":"Junior	", "des":"Age 7 to 9", "price":iyfaaoPrice["SH2"]
                },
                3:{
                "id":3,"number":"SH3", "name":"Emerging", "des":"Age 9 to 12","price":iyfaaoPrice["SH3"]
                },
                4:{
                "id":4,"number":"SH4", "name":"Youth","des":"Age 12 to 15", "price":iyfaaoPrice["SH4"]
                },
                5:{
                "id":5,"number":"SH5", "name":"Young Artist","des":"Age 15 to 18", "price":iyfaaoPrice["SH5"]
                },
                6:{
                "id":6,"number":"SH6", "name":"Artist","des":"Age 18 or above", "price":iyfaaoPrice["SH6"]
                },
        
        },
      
    },

}

export default iyfaoGroupCat;